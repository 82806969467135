import React, { useEffect, useState } from "react";
import Sliders from "../Home/Slider";
import HeaderMain from "../Layout/HeaderMain";
import { allapiAction } from "../../Redux/common/action";
import { useDispatch, useSelector } from "react-redux";
import { URL } from "../../Redux/common/url";
import { Link, useNavigate } from "react-router-dom";
import AppSlider from "../Home/AppSlider";
import CategoryList from "./CategoryList";

function Home2() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const get_home_all_pro = useSelector((state) =>
    state?.allapi?.get_home_all_pro ? state?.allapi?.get_home_all_pro : {}
  );

  console.log(get_home_all_pro);

  const [value, setValue] = useState("");

  useEffect(() => {
    if (typeof window !== "undefined") {
      setValue(localStorage.getItem("access_token"));
    } else {
      setValue("");
    }
  }, []);

  useEffect(() => {
    dispatch(allapiAction.gethomePro({}));
  }, []);
  return (
    <div class="home22 AppPro ">
      {/* <HeaderMain /> */}
      {/* <div class="marquee">
        <span>
          Exclusive offers on Men's Clothing - Up to 50% off! Hurry up!
        </span>
      </div> */}
      <CategoryList/>
      <AppSlider />
      <div class="marquee">
        <span>
          Exclusive offers on Men's Clothing - Up to 50% off! Hurry up!
        </span>
      </div>
      {/* <!-- Categories 70---> */}
      <div class="home2222">
        <div class="category">
          <div class="box">
            <div
              class="content"
              onClick={() => navigate(`/category-products/men`)}
            >
              <img
                src="https://img.freepik.com/free-photo/bohemian-man-with-his-arms-crossed_1368-3542.jpg?t=st=1732173527~exp=1732177127~hmac=271065ccc16007ef2db326777fb5faf652756bcf902366c8a549e37315cfb680&w=740"
                alt="Men Category"
              />
              <h2>Men</h2>
            </div>
          </div>

          <div class="box">
            <div
              class="content"
              onClick={() => navigate(`/category-products/women`)}
            >
              <img
                src="https://img.freepik.com/free-photo/portrait-smiling-beautiful-woman_329181-3520.jpg?ga=GA1.1.1176391215.1690874993&semt=ais_hybrid"
                alt="Women Category"
              />
              <h2>Women</h2>
            </div>
          </div>
        </div>
      </div>
      {/* <a href="/proccess?amount=500&id=162&vpa=merchant1295142.augp@aubank&payeeName=Bombay%20enterprises">
        Process
      </a> */}
      {/* <!-- Products Section 150--> */}
      <section class="products home22">
        <h2 class="animated-headinggg">Products</h2>
        <div class="product-row">
          {get_home_all_pro?.Product &&
            get_home_all_pro?.Product?.map((data, i) => {
              const delaydata = i * 100;
              return (
                i < 4 && (
                  <div class="product col-6">
                    <img
                      onClick={() =>
                        navigate(`/product-details/${data?.pro_sulg}`)
                      }
                      src={URL.API_BASE_URL + data?.featureImage}
                      alt="Product 1"
                    />
                    <p
                      onClick={() =>
                        navigate(`/product-details/${data?.pro_sulg}`)
                      }
                      class="name"
                    >
                      {data?.title}
                    </p>
                    {value && <p class="price">₹{data?.afterdiscountprice}</p>}
                  </div>
                )
              );
            })}
        </div>
        <div class="home2222 mt-3 mb-3">
          <div class="image-container">
            <img src="/rilindia1.webp" alt="Responsive Image" />
          </div>
        </div>
        <div class="product-row">
          {get_home_all_pro?.Product &&
            get_home_all_pro?.Product?.map((data, i) => {
              const delaydata = i * 100;
              return (
                i > 3 && (
                  <div class="product col-6">
                    <img
                      onClick={() =>
                        navigate(`/product-details/${data?.pro_sulg}`)
                      }
                      src={URL.API_BASE_URL + data?.featureImage}
                      alt="Product 1"
                    />
                    <p
                      onClick={() =>
                        navigate(`/product-details/${data?.pro_sulg}`)
                      }
                      class="name"
                    >
                      {data?.title}
                    </p>
                    {value && <p class="price">₹{data?.afterdiscountprice}</p>}
                  </div>
                )
              );
            })}
        </div>
      </section>
      {/* discount */}
      <div class="home22">
        <div class="jj">
          <div class="container">
            <h2 class="text-center mb-4">Featured Products</h2>
            <div class="row g-3">
              {/* <!-- Card 1 --> */}

              {get_home_all_pro?.Product2 &&
                get_home_all_pro?.Product2?.map((data, i) => {
                  return (
                    i < 9 && (
                      <div class="col" key={i}>
                        <div class="card">
                          <img
                            onClick={() =>
                              navigate(`/product-details/${data?.pro_sulg}`)
                            }
                            src={URL.API_BASE_URL + data?.featureImage}
                            alt="All-day Freshness"
                          />
                          <h5
                            onClick={() =>
                              navigate(`/product-details/${data?.pro_sulg}`)
                            }
                            class="card-title"
                          >
                            {data?.title}
                          </h5>
                          {value && (
                            <p class="card-text">₹{data?.afterdiscountprice}</p>
                          )}
                        </div>
                      </div>
                    )
                  );
                })}
              {/* <div class="col">
                     <div class="card">
                         <img src="https://images-na.ssl-images-amazon.com/images/I/61GsR0U3qPL._SLDPMOBCAROUSELAUTOCROP288221_MCnd_AC_SR462,693_.jpg" alt="All-day Freshness"/>
                         <h5 class="card-title">All-day Freshness</h5>
                         <p class="card-text">Up to 40% Off</p>
                     </div>
                 </div>
                
                 <div class="col">
                     <div class="card">
                         <img src="https://insertface.com/fb/1095/woolen-clothes-women-face-1094864-bd0g3-fb.jpg" alt="Baby Care Range"/>
                         <h5 class="card-title">Baby Care Range</h5>
                         <p class="card-text">Up to 30% Off</p>
                     </div>
                 </div>
                
                 <div class="col">
                     <div class="card">
                         <img src="https://i.pinimg.com/564x/cc/2b/4f/cc2b4f93f98bb4db88bcd7601644e15b.jpg" alt="Eyeshadow Palette"/>
                         <h5 class="card-title">Eyeshadow Palette</h5>
                         <p class="card-text">Under ₹189</p>
                     </div>
                 </div>
                 
                 <div class="col">
                     <div class="card">
                         <img src="https://image.made-in-china.com/202f0j00hOjEoCbdLpzq/2018-New-Man-s-Round-Neck-Fashion-Wool-Sweater-Round-Neck-Wholesale-Pullover.webp" alt="AI-ENC | HD Calling"/>
                         <h5 class="card-title">AI-ENC | HD Calling</h5>
                         <p class="card-text">Spl. Price ₹799</p>
                     </div>
                 </div>
                 
                 <div class="col">
                     <div class="card">
                         <img src="https://assets.myntassets.com/h_1440,q_100,w_1080/v1/assets/images/4453297/2024/6/25/69adb2c4-fb31-4394-9151-678653b149a11719298757061-HRX-by-Hrithik-Roshan-Men-Olive-Green-Solid-Active-Bomber-Ja-1.jpg" alt="Revoltt FS1"/>
                         <h5 class="card-title">Revoltt FS1</h5>
                         <p class="card-text">From ₹1,199</p>
                     </div>
                 </div>
                 
                 <div class="col">
                     <div class="card">
                         <img src="https://5.imimg.com/data5/XV/JQ/MY-65715759/nehru-jacket-modi-jacket-500x500.jpg" alt="Biggest Price Drop"/>
                         <h5 class="card-title">Biggest Price Drop</h5>
                         <p class="card-text">Spl. Price ₹899</p>
                     </div>
                 </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* discount end */}
      {/* <!-- Advertisement Cards 300*100--> */}
      <div class="adver">
        <section class="advertisements">
          <div class="ad-card">
            <div class="card">
              <img
                src="https://img.freepik.com/free-vector/flat-horizontal-banner-template-black-friday-sale_23-2150852978.jpg?semt=ais_hybrid"
                alt="Ad 1"
              />
            </div>
          </div>
          <div class="ad-card">
            <div class="card">
              <img
                src="https://img.freepik.com/free-psd/special-sales-banner-template_23-2148975924.jpg?semt=ais_hybrid"
                alt="Ad 2"
              />
            </div>
          </div>
        </section>
      </div>
      {/* <!-- About Us 300x150--> */}
      {/* <section class="about-us">
 <h2>About Us</h2>
 <img src="https://t4.ftcdn.net/jpg/02/70/36/79/360_F_270367957_mZ2HNmTtQeQHQH4MkUDTXBf3fM8xq2mS.jpg" alt="About Us Banner"/>
 <p class="animated-text">Ril India is a ecommerce company that provides a wide range of products for men, women, and kids. We aim to provide the best products and a seamless shopping experience for all our customers.</p>
</section> */}
      {/* <!-- cc --> */}
      {/* about section animation */}
      <div className="header-animated mt-3">
        {/* Content before waves */}
        <div className="inner-header flex">
          {/* Logo and description */}
          <svg
            version="1.1"
            className="logo"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 500 500"
          >
            <path
              fill="#FFFFFF"
              stroke="#000000"
              strokeWidth="10"
              d="M57,283"
            />
            <g>
              <path
                fill="#fff"
                d="M250.4,0.8C112.7,0.8,1,112.4,1,250.2c0,137.7,111.7,249.4,249.4,249.4c137.7,0,249.4-111.7,249.4-249.4C499.8,112.4,388.1,0.8,250.4,0.8z M383.8,326.3c-62,0-101.4-14.1-117.6-46.3c-17.1-34.1-2.3-75.4,13.2-104.1c-22.4,3-38.4,9.2-47.8,18.3c-11.2,10.9-13.6,26.7-16.3,45c-3.1,20.8-6.6,44.4-25.3,62.4c-19.8,19.1-51.6,26.9-100.2,24.6l1.8-39.7c35.9,1.6,59.7-2.9,70.8-13.6c8.9-8.6,11.1-22.9,13.5-39.6c6.3-42,14.8-99.4,141.4-99.4h41L333,166c-12.6,16-45.4,68.2-31.2,96.2c9.2,18.3,41.5,25.6,91.2,24.2l1.1,39.8C390.5,326.2,387.1,326.3,383.8,326.3z"
              />
            </g>
          </svg>
          <p>
            Ril India is an e-commerce company that provides a wide range of
            products for men, women, and kids. We aim to provide the best
            products and a seamless shopping experience for all our customers.
          </p>
        </div>

        {/* Waves Container */}
        <div>
          <svg
            className="waves"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 24 150 28"
            preserveAspectRatio="none"
            shapeRendering="auto"
          >
            <defs>
              <path
                id="gentle-wave"
                d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
              />
            </defs>
            <g className="parallax">
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="0"
                fill="rgba(255,255,255,0.7)"
              />
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="3"
                fill="rgba(255,255,255,0.5)"
              />
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="5"
                fill="rgba(255,255,255,0.3)"
              />
              <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
            </g>
          </svg>
        </div>
      </div>
      ;{/* <!-- Events Section --> */}
      <div class="events-sectionn mt-3">
        <div class="collage-container">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div class="central-logo">RIL INDIA EVENTS</div>
        </div>
      </div>
      {/* about animation end */}
      {/* brand section */}
      <div class="ril_brands">
        <h1>Our Brands</h1>
        <div class="row">
          <div className="col-6">
            <div class="brand">
              <img
                src="https://api.rilindia.biz/static/createBrand/-kqM9Hc0c-zig_zagger.jpg"
                alt="Zig Zagger"
              />
            </div>
          </div>
          <div className="col-6">
            <div class="brand">
              <img
                src="https://api.rilindia.biz/static/createBrand/3m6-jibNG-brand_294.jpg"
                alt="Brand 294"
              />
            </div>
          </div>
        </div>
      </div>
      {/* brand section end*/}
      {/* <!-- Blogs Section 300*150--> */}
      <div class="jm">
        <section class="blogs">
          <h2 class="animated-heading">Latest Blogs</h2>
          <div class="blog-row">
            <div class="blog-item">
              <div class="blog-content">
                <img
                  src="https://img.freepik.com/free-photo/networking-media-sharing-icons-graphic-concept_53876-120836.jpg?t=st=1732103826~exp=1732107426~hmac=9904453f1719085172e3e9a6dba51d2872d26432c7aa9f9dbc2c7d9bb0e0871f&w=740"
                  alt="Blog 1"
                />
                <p>Blog 1</p>
              </div>
            </div>
            <div class="blog-item">
              <div class="blog-content">
                <img
                  src="https://img.freepik.com/free-photo/aerial-view-man-typing-retro-typewriter_53876-13416.jpg?t=st=1732103966~exp=1732107566~hmac=e7b783f03ec2bc496a1abec1dcad0ab83945f4c50ec74475dc3818dda11a45f3&w=360"
                  alt="Blog 2"
                />
                <p>Blog 2</p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <br></br>
      <br></br>
      <br></br>
      {/* 
<!-- Footer --> */}
    </div>
  );
}

export default Home2;
