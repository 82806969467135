// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
// import Image from "next/image";
// import Link from "next/link";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { allapiAction } from "../../Redux/common/action";
// import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";

function FooterMain({ footerData }) {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  // createSubscription

  const navigate = useNavigate();

  const [Subscr, setSubscr] = useState("");

  const get_pages = useSelector((state) =>
    state?.allapi?.get_pages ? state?.allapi?.get_pages : []
  );

  //   const get_footer_all_data = footerData?.data;
  const get_footer_all_data = useSelector((state) =>
    state?.allapi?.get_footer_all_data ? state?.allapi?.get_footer_all_data : {}
  );

  //   console.log(get_footer_all_data);

  const hendleSubscrition = (event) => {
    event.preventDefault();
    if (!Subscr) {
      toast.error("Please enter your email");
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(Subscr)) {
      toast.error("invalid email");
    } else {
      dispatch(allapiAction.createSubscription({ email: Subscr }));
      setSubscr("");
      return () => {};
    }
  };

  useEffect(() => {
    // dispatch(allapiAction.getPages({}));
    dispatch(allapiAction.footerPagepageListetPu({}));
    return () => {};
  }, []);

  const [value, setValue] = useState("");

  useEffect(() => {
    if (typeof window !== "undefined") {
      setValue(localStorage.getItem("access_token"));
      if (localStorage.getItem("access_token")) {
        // 1
        dispatch(allapiAction.getBussProfile1({}));
      }
    } else {
      setValue("");
    }
  }, []);

  //   console.log(get_footer_all_data?.footerPages);

  function isWebView() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Example for Android WebView (check for "wv")
    if (/wv/.test(userAgent) || /Android.*WebView/.test(userAgent)) {
      return true;
    }

    // Example for iOS WebView (check for "iPhone OS" without "Safari")
    if (/iPhone|iPod|iPad/.test(userAgent) && !/Safari/.test(userAgent)) {
      return true;
    }

    return false; // Assume it's a browser if no WebView signature is detected
  }

  if (isWebView()) {
    console.log("Running inside a WebView");
  } else {
    console.log("Running inside a browser");
  }

  return (
    <div>
      {" "}
      <div class="container-fluid px-5 pt-3 bg-light">
        <div class="row">
          <div
            class="col-sm-2 text-left p-0"
            data-aos="fade-right"
            data-aos-delay={"100"}
          >
            <p class="mt-3 kit">CORPORATE INFO</p>
            <p class="kit1">
              <Link
                style={{
                  textDecoration: "none",
                  color: "slategray",
                  display: "block",
                }}
                to={"/about"}
              >
                About Us
              </Link>
            </p>

            <p class="kit1">
              <Link
                style={{
                  textDecoration: "none",
                  color: "slategray",
                  display: "block",
                }}
                // target="_blank"
                to={"/compliance"}
              >
                Compliance
              </Link>
            </p>
          </div>
          <div
            class="col-sm-2 text-left p-0"
            data-aos="fade-right"
            data-aos-delay={"200"}
          >
            <p class="mt-3 kit">HELP</p>

            <p class="kit1">
              <Link
                style={{
                  textDecoration: "none",
                  color: "slategray",
                  display: "block",
                }}
                to={"/faqs"}
              >
                Faq
              </Link>
            </p>
            <p class="kit1">
              <Link
                style={{
                  textDecoration: "none",
                  color: "slategray",
                  display: "block",
                }}
                to={"/contact"}
              >
                Contact Us
              </Link>
            </p>
            <p class="kit1">
              <Link
                style={{
                  textDecoration: "none",
                  color: "slategray",
                  display: "block",
                }}
                to={"/transparency"}
              >
                Transparency
              </Link>
            </p>
          </div>
          <div
            class="col-sm-2 text-left p-0"
            data-aos="fade-right"
            data-aos-delay={"300"}
          >
            <p class="mt-3 kit">COMPANY POLICY</p>

            <p class="kit1">
              <a
                style={{
                  textDecoration: "none",
                  color: "slategray",
                  display: "block",
                }}
                target="_blank"
                href={"/CancellationPolicy.pdf"}
              >
                {/* Refund and Return Policy */}
                Cancellation Policy
              </a>
            </p>
            <p class="kit1">
              <a
                style={{
                  textDecoration: "none",
                  color: "slategray",
                  display: "block",
                }}
                target="_blank"
                href={"/termsandconditions.pdf"}
              >
                Terms & Conditions
              </a>
            </p>
            <p class="kit1">
              <Link
                style={{
                  textDecoration: "none",
                  color: "slategray",
                  display: "block",
                }}
                // target="_blank"
                to={"/privacy-policy"}
              >
                Privacy Policy
              </Link>
            </p>
            {/* <p class="kit1">
              <Link
                style={{
                  textDecoration: "none",
                  color: "slategray",
                  display: "block",
                }}
                href={"/transparency"}
              >
                Transparency
              </Link>
            </p> */}
          </div>
          {/* <div class="col-sm-2 text-left p-0">
            <p class="mt-3 kit">QUICK LINKS</p>
            <p class="kit1">
              <Link
                style={{
                  textDecoration: "none",
                  color: "slategray",
                  display: "block",
                }}
                href={"/about"}
              >
                About
              </Link>
            </p>
            
          </div> */}
          {get_footer_all_data?.footerPages &&
            get_footer_all_data?.footerPages?.map((data, i) => {
              return (
                <div class="col-sm-2 text-left p-0">
                  <p class="mt-3 kit">{data?.title}</p>
                  <p class="kit1">
                    {data?.pagelist &&
                      data?.pagelist?.map((data2, ind) => {
                        return (
                          <Link
                            style={{
                              textDecoration: "none",
                              color: "slategray",
                              display: "block",
                            }}
                            key={ind}
                            to={"/p/" + data2?.page_sulg}
                          >
                            {" "}
                            {data2?.pageName}
                          </Link>
                        );
                      })}
                    {/* <a
                      style={{ textDecoration: "none", color: "slategray" }}
                      href="About.html"
                    >
                      {" "}
                      About Us
                    </a>
                    <br />
                    <a href="team.html"> Our Team</a> */}
                    {/* <br />
                    Download Incentive Plan
                    <br />
                    Compliance */}
                  </p>
                </div>
              );
            })}

          {/* <div class="col-sm-2 text-left pl-3">
            <p class="mt-3 kit">HELP</p>
            <p class="kit1">
              {" "}
              FAQ
              <br />
              Contact Us
              <br />
              Transparency
            </p>
          </div>
          <div class="col-sm-3 text-left">
            <p class="mt-3 kit">POLICY</p>
            <p class="kit2">
              Return And Refund Policy
              <br />
              License Transfer Policy
              <br />
              Code Of Ethics And Conducts
              <br />
              Direct Seller Contract
              <br />
       
              <br />
              Privacy Policy
              <br />
              Website T&C
              <br />
              Unauthorized Distributor & False Information
            </p>
          </div> */}
          <div
            class="col-sm-2 text-left kitfooter"
            data-aos="fade-right"
            data-aos-delay={"400"}
          >
            <p class="mt-3 kit-2">Follow us</p>
            <a
              href="https://www.instagram.com/ril_india_official_page?utm_source=qr&igsh=OHF5MHMxNDI3bnN2"
              target={"_blank"}
            >
              <i
                style={{ fontSize: "20px", color: "#ff002d" }}
                class="fa fa-instagram"
              ></i>
            </a>
            <a
              href="https://api.whatsapp.com/send/?phone=7880006147"
              target={"_blank"}
            >
              <i
                style={{ fontSize: "20px", color: "green" }}
                class="fa fa-whatsapp"
              ></i>
            </a>

            {/* <i class="fa fa-instagram" aria-hidden="true"></i> */}
            {/* <i
              style={{ fontSize: "20px", color: "skyblue" }}
              class="fa fa-twitter"
            ></i> */}

            {/* <i
              style={{ fontSize: "20px", color: "darkblue" }}
              class="fa fa-facebook"
            ></i>
            <i
              style={{ fontSize: "20px", color: "dodgerblue" }}
              class="fa fa-telegram"
            ></i> */}
            {/* </p> */}
          </div>
          <div
            class="col-sm-3 text-left p-0"
            data-aos="fade-right"
            data-aos-delay={"500"}
          >
            <p class="mt-3 kit-1">
              By entering your email address, you agree to receive Rilindia
              offers, promotions, and other commercial messages. You may
              unsubscribe at any time.
            </p>
            <p class="kit-1">
              {" "}
              Copyright ©️ 2020 RILINDIA. All rights reserved.
              <br />
              <span style={{ fontSize: "13px", fontFamily: "Courier New" }}>
                © 2020 Rilindia all copyrights reserved
              </span>
            </p>
          </div>
        </div>

        {/* <div class="row">
          <div class="col-sm-12 p-0">
            <p class="tik">MEN:</p>
            <span class="tik1">
              {" "}
              TOP WEAR | ETHNIC WEAR | BOTTOM WEAR | FOOTWEAR | ACCESSORIES
            </span>
            <br />
            <p class="tik">WOMEN:</p>
            <span class="tik1">
              WESTERN WEAR | ETHNIC & FUSION WEAR | FOOTWEAR | BEAUTY |
              ACCESSORIES
            </span>
          </div>
        </div> */}
        {/* <!-- T-SHIRT | FORMAL SHIRTS4 | FORMAL PANTS4 | COLLAR T-SHIRT3 |  --> */}
        <div class="row p-0">
          <div class="col-sm-12 p-0">
            <hr />
          </div>
        </div>

        <div class="row">
          <div class="col-sm-2" data-aos="fade-right" data-aos-delay={"100"}>
            <p class="tik2 pl-4">Registered Office Address :</p>

            {/* <span class="tik3">
              Ril india , Amrit vihar street no 7 a block Burari north Delhi
              India 110084, India CIN:
            </span>
            <center>
              <span class="tik3">
                18004198684 (call us between 10am to 6pm from Monday to
                Saturday)
              </span>
            </center> */}
          </div>
          <div class="col-sm-8" data-aos="fade-right" data-aos-delay={"200"}>
            {/* <p class="tik2 pl-4">Registered Office Address :</p> */}

            <span class="tik3">
              Ril india , Amrit vihar street no 7 a block Burari north Delhi
              India 110084, India CIN: <br />
              18004198684 (call us between 10am to 6pm from Monday to Saturday)
            </span>
            {/* <center>
              <span class="tik3">
                18004198684 (call us between 10am to 6pm from Monday to
                Saturday)
              </span>
            </center> */}
          </div>
        </div>

        <div class="row mt-4">
          <div
            class="col-sm-12 mt-2 p-0"
            // data-aos="fade-right"
            // data-aos-delay={"0"}
          >
            <p class="bit">
              Creating Lifestyle{" "}
              <span style={{ fontWeight: "650" }}>Business Opportunity</span>{" "}
              For Bharat
            </p>
          </div>
        </div>

        <div class="row">
          <div
            class="col-sm-12 mt-4 p-0"
            // data-aos="fade-right"
            // data-aos-delay={"0"}
          >
            <p class="pok">
              RIL INDIA is direct selling private limited company which aims is
              to sell products at affordable prices and to reach the globally.
            </p>
          </div>
        </div>
      </div>
      {isWebView() && (
        <footer class="bottom-nav ftm">
          <div class="nav-item" onClick={() => navigate("/")}>
            <i class="fa fa-home" aria-hidden="true"></i>
            {/* <img src="https://static.vecteezy.com/system/resources/thumbnails/022/750/436/small/3d-home-icon-free-png.png" alt="Home" /> */}
            <span>Home</span>
          </div>
          <div class="nav-item" onClick={() => navigate("/new-launch")}>
            <i class="fa fa-rocket" aria-hidden="true"></i>

            <span>New launch</span>
          </div>
          {/* <div class="nav-item">
       <img src="https://png.pngtree.com/element_our/20190528/ourmid/pngtree-distribution-map-cartoon-icon-design-image_1168817.jpg" alt="Categories" />
       <span>Categories</span>
     </div> */}

          <div class="nav-item" onClick={() => navigate("/categories")}>
            {/* <img src="https://cdn-icons-png.flaticon.com/512/724/724664.png" alt="Contact" /> */}
            <img
              src="../assets/categories.png"
              alt="Contact"
              style={{ width: "20px", height: "20px" }}
            />
            {/* <i class="fa fa-envelope" aria-hidden="true"></i> */}
            <span>Categories</span>
          </div>
          {/* <div class="nav-item" onClick={() => navigate("/about")}>
            <i class="fa fa-user" aria-hidden="true"></i>
             
            <span>About</span>
          </div> */}

          {value ? (
            <div class="nav-item" onClick={() => navigate("/account")}>
              <i class="fa fa-user" aria-hidden="true"></i>
              {/* <img src="https://png.pngtree.com/png-vector/20241104/ourlarge/pngtree-silver-user-circle-icon-profile-person-vector-png-image_14240326.png" alt="Account" /> */}
              <span>My Account</span>
            </div>
          ) : (
            <div class="nav-item" onClick={() => navigate("/login")}>
              <i class="fa fa-user" aria-hidden="true"></i>
              {/* <img src="https://png.pngtree.com/png-vector/20241104/ourlarge/pngtree-silver-user-circle-icon-profile-person-vector-png-image_14240326.png" alt="Account" /> */}
              <span>My Account</span>
            </div>
          )}
        </footer>
      )}
    </div>
  );
}

export default FooterMain;
