// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { toast } from "react-toastify";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
// import { allapiAction } from "../../Redux/common/action";

function Sliders({ sliderdate }) {
  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  const dispatch = useDispatch();
  // createSubscription

  const [Subscr, setSubscr] = useState("");

  // const slider_list = sliderdate;
  const slider_list = useSelector((state) =>
    state?.allapi?.slider_list ? state?.allapi?.slider_list : {}
  );

  const hendleSubscrition = (event) => {
    event.preventDefault();
    if (!Subscr) {
      toast.error("Please enter your email");
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(Subscr)) {
      toast.error("invalid email");
    } else {
      dispatch(allapiAction.createSubscription({ email: Subscr }));
      setSubscr("");
      return () => {};
    }
  };

  useEffect(() => {
    // slider_list

    dispatch(allapiAction.getsliderList({forslider: "web"}));
    return () => {};
  }, []);

  // console.log(sliderdate);

  return (
    <div>
      <section className="home-slider position-relative mb-5">
        <div className="">
          <div className="home-slide-cover mt-5">
            <div
              className="homePageSlider1"
              data-aos="fade-top"
              data-aos-delay={"100"}
            >
              <Slider {...settings}>
                {slider_list?.sliders &&
                  slider_list?.sliders?.map((data, i) => {
                    return (
                      <div
                        key={i}
                        className="single-hero-slider single-animation-wrap homePageSlider "
                        style={{
                          backgroundImage:
                            "url(/assets/imgs/slider/banner-0.jpg)",
                        }}
                      >
                        <img
                          src={URL.API_BASE_URL + data?.image}
                          alt={"slider" + i}
                        />
                        <div className="slider-content">
                          {/* <h1 className="display-2 mb-40">
                      Don’t miss amazing
                      <br />
                      grocery deals
                    </h1>
                    <p className="mb-65">Sign up for the daily newsletter</p> */}
                          {/* <form className="form-subcriber d-flex">
                      <input
                        type="email"
                        placeholder="Your emaill address"
                        onChange={(e) => {
                          setSubscr(e.target.value);
                        }}
                      />
                      <button
                        className="btn"
                        type="submit"
                        onClick={(e) => {
                          hendleSubscrition(e);
                        }}
                      >
                        Subscribe
                      </button>
                    </form> */}
                        </div>
                      </div>
                    );
                  })}

                {/* <div
                  className="single-hero-slider single-animation-wrap homePageSlider"
                  style={{
                    backgroundImage: "url(/assets/imgs/slider/banner-1.jpg)",
                  }}
                >
                  <img src="/assets/imgs/slider/banner-1.jpg" alt="" />
                  <div className="slider-content"> */}
                {/* <h1 className="display-2 mb-40">
                      Fresh Vegetables
                      <br />
                      Big discount
                    </h1>
                    <p className="mb-65">
                      Save up to 50% off on your first order
                    </p> */}
                {/* <form className="form-subcriber d-flex">
                      <input
                        type="email"
                        placeholder="Your emaill address"
                        onChange={(e) => {
                          setSubscr(e.target.value);
                        }}
                      />
                      <button
                        className="btn"
                        type="submit"
                        onClick={(e) => {
                          hendleSubscrition(e);
                        }}
                      >
                        Subscribe
                      </button>
                    </form> */}
                {/* </div>
                </div> */}
                {/* <div
                  className="single-hero-slider single-animation-wrap homePageSlider"
                  style={{
                    backgroundImage: "url(/assets/imgs/slider/banner-2.jpg)",
                  }}
                >
                  <img src="/assets/imgs/slider/banner-2.jpg" alt="" />
                  <div className="slider-content"> */}
                {/* <h1 className="display-2 mb-40">
                      Fresh Vegetables
                      <br />
                      Big discount
                    </h1>
                    <p className="mb-65">
                      Save up to 50% off on your first order
                    </p> */}
                {/* <form className="form-subcriber d-flex">
                      <input
                        type="email"
                        placeholder="Your emaill address"
                        onChange={(e) => {
                          setSubscr(e.target.value);
                        }}
                      />
                      <button
                        className="btn"
                        type="submit"
                        onClick={(e) => {
                          hendleSubscrition(e);
                        }}
                      >
                        Subscribe
                      </button>
                    </form> */}
                {/* </div>
                </div> */}
                {/* <div
                  className="single-hero-slider single-animation-wrap homePageSlider"
                  style={{
                    backgroundImage: "url(/assets/imgs/slider/banner-3.jpg)",
                  }}
                >
                  <img src="/assets/imgs/slider/banner-3.jpg" alt="" />
                  <div className="slider-content"> */}
                {/* <h1 className="display-2 mb-40">
                      Fresh Vegetables
                      <br />
                      Big discount
                    </h1>
                    <p className="mb-65">
                      Save up to 50% off on your first order
                    </p> */}
                {/* <form className="form-subcriber d-flex">
                      <input
                        type="email"
                        placeholder="Your emaill address"
                        onChange={(e) => {
                          setSubscr(e.target.value);
                        }}
                      />
                      <button
                        className="btn"
                        type="submit"
                        onClick={(e) => {
                          hendleSubscrition(e);
                        }}
                      >
                        Subscribe
                      </button>
                    </form> */}
                {/* </div>
                </div> */}
              </Slider>
            </div>

            <div className="slider-arrow hero-slider-1-arrow"></div>
          </div>
        </div>
      </section>
      {/* <div
        style={{
          backgroundImage: `url("./assets/imgs/slider/slider-1.png")`,
        }}
      ></div> */}
    </div>
  );
}

export default Sliders;
