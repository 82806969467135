import React, { useEffect, useState } from "react";
import HeaderMain from "../Layout/HeaderMain";
import FooterMain from "../Layout/FooterMain";
import { useDispatch } from "react-redux";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
function Notification() {
  const dispatch = useDispatch();

  const [notiList, setnotiList] = useState([]);

  console.log(notiList);

  TimeAgo.addDefaultLocale(en);

  // Create a TimeAgo instance
  const timeAgo = new TimeAgo("en-US");

  useEffect(() => {
    getList();
    return () => {};
  }, []);

  const getList = async () => {
    // getnotificationPubList

    const response = await dispatch(allapiAction?.getnotificationPubList());

    setnotiList(response?.data);
  };
  return (
    <div>
      <HeaderMain />
      <div class="container my-4">
        {notiList &&
          notiList?.map((data, i) => {
            return (
              <div class="notification-card">
                <div class="notification-header">
                  <div class="notification-icon">RIL</div>
                  <div>
                    <h6 class="m-0">{data?.title}</h6>
                    <p class="mt-2">{data?.description}</p>
                    <small>
                      NEW | {timeAgo.format(new Date(data?.createdAt))}
                    </small>
                  </div>
                </div>

                {data?.image && (
                  <div class="notification-body mt-3">
                    <img
                      src={URL?.API_BASE_URL + data?.image}
                      alt="Sale Banner"
                    />
                  </div>
                )}
              </div>
            );
          })}
        {/* <div class="notification-card">
          <div class="notification-header">
            <div class="notification-icon">N</div>
            <div>
              <h6 class="m-0">STILL THINKING ABOUT CROCS? 👀</h6>
              <p class="mt-2">
                Come back & make their styles yours, as they're on steal deal @
                the Republic of Style Sale!
              </p>
              <small>NEW | 23 hours ago</small>
            </div>
          </div>

          <div class="notification-body mt-3">
            <img src="assets/imgs/02.png" alt="Sale Banner" />
          </div>
        </div>
        <div class="notification-card">
          <div class="notification-header">
            <div class="notification-icon">N</div>
            <div>
              <h6 class="m-0">HEY, REGISTER & UNLOCK OFFERS! 💰</h6>
              <p class="mt-2">
                It's easy! Just sign up & open the door to discounts like{" "}
                <b>EXTRA 15% OFF</b> with code <b>NFNEW15!</b>
              </p>
              <small>NEW | 1 day ago</small>
            </div>
          </div>
        </div> */}

        {/* <div class="notification-card">
      <div class="notification-header">
        <div class="notification-icon">N</div>
        <div>
          <h6 class="m-0">HEY, REGISTER & UNLOCK OFFERS! 💰</h6>
          <small>NEW | 1 day ago</small>
        </div>
      </div>
      <p class="mt-2">It's easy! Just sign up & open the door to discounts like <b>EXTRA 15% OFF</b> with code <b>NFNEW15!</b></p>
    </div> */}
      </div>
      <FooterMain />
    </div>
  );
}

export default Notification;
