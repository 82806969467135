import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { URL } from "../../Redux/common/url";
import { useNavigate } from "react-router-dom";

function CategoryList() {
  const all_categories_List = useSelector((state) =>
    state?.allapi?.all_categories_List ? state?.allapi?.all_categories_List : []
  );

  const navigate = useNavigate();
  const [activeCate, setactiveCate] = useState(0);
  const [subCate, setsubCate] = useState([]);
  console.log(subCate);

  useEffect(() => {
    if (all_categories_List?.length > 0) {
      setsubCate(all_categories_List[0]?.subcates);
    }

    return () => {};
  }, [all_categories_List]);

  const hendleTochangeCate = (i) => {
    setactiveCate(i);
    setsubCate(all_categories_List[i]?.subcates);
  };
  return (
    <div className="AppPro">
      <div className="CategoryListapp">
        <div class="container mt-4">
          <div class="d-flex justify-content-around mb-3 cateLi">
            {all_categories_List &&
              all_categories_List?.map((data, i) => {
                return (
                  <button
                    onClick={() => {
                      hendleTochangeCate(i);
                    }}
                    class={
                      activeCate == i
                        ? "tab-btn btn btn-light active"
                        : "tab-btn btn btn-light"
                    }
                  >
                    <img
                      src={URL?.API_BASE_URL + data?.image}
                      alt={data?.name}
                    />
                    {data?.name}
                  </button>
                );
              })}
            {/* <button class="tab-btn btn btn-light">
              <img src="https://via.placeholder.com/30" alt="Women" />
              Women
            </button>
            <button class="tab-btn btn btn-light">
              <img src="https://via.placeholder.com/30" alt="Men" />
              Men
            </button>
            <button class="tab-btn btn btn-light">
              <img src="https://via.placeholder.com/30" alt="Kids" />
              Kids
            </button>
            <button class="tab-btn btn btn-light">
              <img src="https://via.placeholder.com/30" alt="Home" />
              Home
            </button> */}
          </div>

          <div class="d-flex text-center CategoryListr">
            {subCate &&
              subCate?.map((data, i) => {
                return (
                  <div
                    class="col-3"
                    onClick={() =>
                      navigate(`/sub-category-product/${data?.cate_sulg}`)
                    }
                  >
                    <img
                      src={URL?.API_BASE_URL + data?.image}
                      alt={data?.name}
                      style={{ width: "80px", height: "80px" ,borderRadius:"40px" }}
                    />

                    <p
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data?.name}
                    </p>
                  </div>
                );
              })}
            {/* <div class="col-3">
              <img src="https://via.placeholder.com/80" alt="Westernwear" />
              <p>Westernwear</p>
            </div>
            <div class="col-3">
              <img src="https://via.placeholder.com/80" alt="Indianwear" />
              <p>Indianwear</p>
            </div>
            <div class="col-3">
              <img src="https://via.placeholder.com/80" alt="Footwear" />
              <p>Footwear</p>
            </div>
            <div class="col-3">
              <img src="https://via.placeholder.com/80" alt="Winterwear" />
              <p>Winterwear</p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CategoryList;
