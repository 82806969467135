import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
// import { allapiAction } from "../../Redux/common/action";
// import { URL } from "../../Redux/common/url";
// import Footer from "../Layout/Footer";
// import Header from "../Layout/Header";
// import ScrollToTop from "../ScrollToTop/ScrollToTop";
import ReactPaginate from "react-paginate";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
// import ImageUploading from "react-images-uploading";
// import ReactImageMagnify from "react-image-magnify";
import { toast } from "react-toastify";

import Select from "react-select";

//   import { setUserName, setUserNumber } from "@/Utils/Auth/Token";
//   import { useRouter } from "next/router";
//   import Header from "../../component/layout/Header";
//   import Footer from "../../component/layout/Footer";
//   import Link from "next/link";
// import { allapiAction } from "@/Redux/common/action";
//   import { URL } from "@/Redux/common/url";
//   import Head from "next/head";

import BounceLoader from "react-spinners/BounceLoader";
//   import Image from "next/image";

import PhoneInput from "react-phone-number-input";
import { parsePhoneNumberFromString } from "libphonenumber-js";
//   import { allapiAction } from "@/Redux/common/action";
import { debounce } from "lodash";
//   import TruncateText from "../../component/TruncateText/TruncateText";
import { URL } from "../../Redux/common/url";
import { allapiAction } from "../../Redux/common/action";
// import { setUserName } from "../../Utils2/Auth/Token";
import { setUserName, setUserNumber } from "../../Utils/Auth/Token";
import { Image } from "react-bootstrap";
import TruncateText from "../TruncateText/TruncateText";
import HeaderMain from "../Layout/HeaderMain";
import FooterMain from "../Layout/FooterMain";
// import Seo from "../Seo/Seo";

function Products({ post }) {
  const data = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const searchp = searchParams.get("searchp");
  const page = searchParams.get("page");

  const router = useNavigate();
  // console.log(data);
  console.log(searchp);
  console.log(page);

  // const { searchp, page } = router.query;

  const id = searchp;

  // const get_seller_ticket_list_public = post;
  const get_seller_ticket_list_public = useSelector((state) =>
    state?.allapi?.user_product_search ? state?.allapi?.user_product_search : {}
  );
  const get_seller_details = useSelector((state) =>
    state?.allapi?.get_seller_details ? state?.allapi?.get_seller_details : {}
  );

  const country_list = useSelector((state) =>
    state?.allapi?.country_list ? state?.allapi?.country_list : []
  );
  const city_list = useSelector((state) =>
    state?.allapi?.city_list ? state?.allapi?.city_list : []
  );

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionCity, setSelectedOptionCity] = useState(null);

  useEffect(() => {
    dispatch(allapiAction.getOneUserPerCountry());
    return () => {};
  }, []);

  const [value, setValue] = useState("");

  useEffect(() => {
    if (typeof window !== "undefined") {
      setValue(localStorage.getItem("access_token"));
    } else {
      setValue("");
    }
  }, []);
  const idRef = useRef(null);
  const [debouncedSearchp, setDebouncedSearchp] = useState("");

  // const prevSearchpRef = useRef(null);

  useEffect(() => {
    console.log(page);
    //   if (searchp !== prevSearchpRef.current) {
    //     prevSearchpRef.current = searchp;

    // Make the API call
    dispatch(
      allapiAction.userSearchProduct({
        id: searchp,
        page: page,
        //   country: selectedOption?._id,
        //   city: selectedOptionCity?._id,
      })
    );
    //   }
  }, [searchp, page]);

  const [itemOffset, setItemOffset] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [pageNo, setpageNo] = useState(1);
  const headers = [
    { name: "Route Name", field: "routeName", sortable: true },
    { name: "From", field: "from", sortable: true },
    { name: "To", field: "to", sortable: false },
    { name: "Action", field: "action", sortable: false },
  ];

  const comments = get_seller_ticket_list_public?.docs
    ? get_seller_ticket_list_public?.docs
    : [];
  const pageCount = get_seller_ticket_list_public?.totalPages
    ? get_seller_ticket_list_public?.totalPages
    : 0;

  const commentsData = useMemo(() => {
    let computedComments = comments;

    // if (search) {
    //   computedComments = computedComments.filter(
    //     (comment) =>
    //       comment.message.toLowerCase().includes(search.toLowerCase()) ||
    //       comment.title.toLowerCase().includes(search.toLowerCase())
    //   );
    // }

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice

    return computedComments.slice(
      (currentPage - 1) * itemsPerPage,
      (currentPage - 1) * itemsPerPage + itemsPerPage
    );
  }, [comments, currentPage, search, sorting, itemsPerPage]);

  const handlePageClick = (event) => {
    // const newOffset = event.selected % (comments.length / 2);
    // setCurrentPage(newOffset + 1);
    // setItemOffset(newOffset * itemsPerPage);

    const datad = event?.selected + 1;

    setpageNo(datad);
    window.scrollTo(0, 110);

    router("/products?searchp=" + searchp + "&page=" + datad);

    // if (!search) {
    //   // dispatch(ShoetoggelAction.getusers(data))
    //   // dispatch(allapiAction.getsellerProductList(data));
    //   dispatch(
    //     allapiAction.userSearchProduct({
    //       id: id,
    //       page: datad,
    //       country: selectedOption?._id,
    //       city: selectedOptionCity?._id,
    //     })
    //   );

    //   return () => {};
    // } else {

    //   dispatch(allapiAction.searchSellerProduct({ key: search, page: datad }));
    //   return () => {};
    // }
  };

  const [showView, setShowView] = useState(false);

  // const handleCloseDelete = () => setShowDelete(false);
  const handleCloseView = () => setShowView(false);

  const [dispImg, setDispImg] = useState("/assets/imgs/shop/product-16-2.jpg");
  const [productImage, setproductImage] = useState([]);
  const [productData, setproductData] = useState({});

  const get_product_details = useSelector((state) =>
    state?.allapi?.get_product_details ? state?.allapi?.get_product_details : {}
  );
  const loading = useSelector((state) =>
    state?.allapi?.loading ? state?.allapi?.loading : false
  );
  const loading1 = useSelector((state) =>
    state?.allapi?.loading1 ? state?.allapi?.loading1 : false
  );

  // console.log(loading);

  useEffect(() => {
    const feImg = URL.API_BASE_URL + get_product_details?.featureImage;

    const allImg = get_product_details?.images?.map((data) => {
      return URL.API_BASE_URL + data?.img;
    });

    if (get_product_details?.images !== undefined) {
      const allImgs = allImg?.filter((data) => {
        return !data.match("undefined");
      });

      const allimgMarge = [feImg, ...allImgs];
      setDispImg(feImg);
      setproductImage(allimgMarge);
    }
    setproductData(get_product_details);
  }, [get_product_details]);

  const hendleModalShowView = (e) => {
    setShowView(true);

    const feImg = URL.API_BASE_URL + e?.featureImage;

    const allImg = e?.images?.map((data) => {
      return URL.API_BASE_URL + data?.img;
    });

    dispatch(allapiAction.productDetails(e?.pro_sulg));
    // dispatch(allapiAction.getsubsubcatehome({}));

    // const allimgMarge = [feImg, ];
    // setDispImg(feImg);
    // setproductImage(allimgMarge);

    // setproductData(e);
    return () => {};
  };

  const [showWhatsappIn, setShowWhatsappIn] = useState(false);
  const handleCloseWhatsappIn = () => setShowWhatsappIn(false);
  const handleOpneWhatsappIn = () => setShowWhatsappIn(true);

  const [whatInq, setwhatInq] = useState({ countryCode: "", whatsappNo: "" });
  const [emailInq, setemailInq] = useState({
    country: "",
    customerEmail: "",
    userName: "",
  });

  const hendleToWhatsapp = (e) => {
    const { name, value } = e.target;
    setwhatInq({ ...whatInq, [name]: value });
  };

  // start

  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode1, setCountryCode1] = useState("");
  const [phoneNumber1, setPhoneNumber1] = useState("");

  const [phoneNumber2, setPhoneNumber2] = useState("");
  const [countryCode12, setCountryCode12] = useState("");
  const [phoneNumber12, setPhoneNumber12] = useState("");
  const handlePhoneChange = (value) => {
    const phone = value || "";
    const phoneNumberObj = parsePhoneNumberFromString(phone);
    const conCode = phoneNumberObj ? phoneNumberObj.countryCallingCode : "";
    const contry = phoneNumberObj ? phoneNumberObj.nationalNumber : "";
    setCountryCode1(conCode);
    setPhoneNumber1(contry);
    setPhoneNumber(value);
  };

  const handlePhoneChange2 = (value) => {
    const phone = value || "";
    const phoneNumberObj = parsePhoneNumberFromString(phone);
    const conCode = phoneNumberObj ? phoneNumberObj.countryCallingCode : "";
    const contry = phoneNumberObj ? phoneNumberObj.nationalNumber : "";
    setCountryCode12(conCode);
    setPhoneNumber12(contry);
    setPhoneNumber2(value);
  };

  const hendleWhatsappIn = () => {
    // whatsappinquiresubmit

    if (!countryCode1) {
      toast.error("Please enter country code");
    } else if (!phoneNumber1) {
      toast.error("Please enter Number");
    } else if (!whatInq?.name) {
      toast.error("Please enter name");
    } else {
      dispatch(
        allapiAction.whatsappinquiresubmit({
          countryCode: countryCode1,
          whatsappNo: phoneNumber1,
          name: whatInq?.name,
          email: whatInq?.email,
          message: whatInq?.message,
          productName: productData?.title,
          productId: productData?._id,
          sellerId: productData?.sellerId?._id,
          sellerName: productData?.sellerId?.fullName,
          sellercountryCode: productData?.sellerId?.countryCode,
          sellerwhatsappNo: productData?.sellerId?.whatsappNumber,
        })
      );
      const userEmailData = JSON.stringify({
        countryCode: countryCode1,
        whatsappNo: phoneNumber1,
        name: whatInq?.name,
        email: whatInq?.email,
      });

      // whatsappNo: whatInq?.whatsappNo,
      setUserNumber(userEmailData);
      // dispatch(allapiAction.getsubsubcatehome({}));
      setShowWhatsappIn(false);
      setShowView(false);

      return () => {};
    }
  };

  // end

  const [showEmailIn, setShowEmailIn] = useState(false);
  const handleCloseEmailIn = () => setShowEmailIn(false);
  const handleOpneEmailIn = () => setShowEmailIn(true);

  const [subList, setsubList] = useState([]);
  const [subListCity, setsubListCity] = useState([]);

  useEffect(() => {
    const dropList = country_list?.map((data, i) => {
      data.value = data?._id;
      data.label = data?._id;

      return data;
    });

    setsubList(dropList);
  }, [country_list]);

  // useEffect(() => {
  //   const dropList = city_list?.map((data, i) => {
  //     data.value = data?._id;
  //     data.label = data?._id;

  //     return data;
  //   });

  //   setsubListCity(dropList);
  // }, [city_list]);

  // useEffect(() => {
  //   const dropList = cityListList?.map((data, i) => {
  //     data.value = data?.stateCode + i;
  //     data.label = data?.name;

  //     return data;
  //   });

  //   setsubListCity(dropList);
  // }, [cityListList]);
  const [subListCityfil, setsubListCityfil] = useState([]);

  // useEffect(() => {
  //   // const found = subListCity.find(
  //   //   (element) => element?.countryCode == selectedOption?.isoCode
  //   // );

  //   const listFilCity =
  //     subListCity &&
  //     subListCity?.filter((data) => {
  //       return data?.countryCode == selectedOption?.isoCode;
  //     });

  //   setsubListCityfil(listFilCity);
  // }, [selectedOption]);

  useEffect(() => {
    // const found = subListCity.find(
    //   (element) => element?.countryCode == selectedOption?.isoCode
    // );

    dispatch(allapiAction.getOneUserPerCity({ country: selectedOption?._id }));
    return () => {};

    // const listFilCity =
    //   subListCity &&
    //   subListCity?.filter((data) => {
    //     return data?.countryCode == selectedOption?._id;
    //   });

    // setsubListCityfil(listFilCity);
  }, [selectedOption]);

  const hendleToEmail = (e) => {
    const { name, value } = e.target;
    setemailInq({ ...emailInq, [name]: value });
  };

  const hendleModalEmailView = (e) => {
    setShowEmailIn(true);

    const feImg = URL.API_BASE_URL + e?.featureImage;

    const allImg = e?.images?.map((data) => {
      return URL.API_BASE_URL + data?.img;
    });
    const allImgs = allImg?.filter((data) => {
      return !data.match("undefined");
    });

    const allimgMarge = [feImg];
    setDispImg(feImg);
    setproductImage(allimgMarge);

    setproductData(e);
  };

  const hendleModalWhatsappView = (e) => {
    setShowWhatsappIn(true);

    const feImg = URL.API_BASE_URL + e?.featureImage;

    const allImg = e?.images?.map((data) => {
      return URL.API_BASE_URL + data?.img;
    });
    const allImgs = allImg?.filter((data) => {
      return !data.match("undefined");
    });

    const allimgMarge = [feImg];
    setDispImg(feImg);
    setproductImage(allimgMarge);

    setproductData(e);
  };

  // start

  const hendleEmailIn = () => {
    // whatsappinquiresubmit

    if (!emailInq?.userName) {
      toast.error("Please enter name");
    } else if (!emailInq?.customerEmail) {
      toast.error("Please enter email");
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(emailInq?.customerEmail)
    ) {
      toast.error("invalid email");
    } else if (!phoneNumber12) {
      toast.error("Please enter number");
    } else {
      dispatch(
        allapiAction.emailenquirysubmit({
          // country: "", customerEmail: "",userName:""
          countryCode: countryCode12,
          whatsappNo: phoneNumber12,
          message: emailInq?.message,
          customerEmail: emailInq?.customerEmail,
          userName: emailInq?.userName,
          productName: productData?.title,
          productId: productData?._id,
          sellerId: productData?.sellerId?._id,
          sellerName: productData?.sellerId?.fullName,
          sellerEmail: productData?.sellerId?.email,
          sellerwhatsappNo: productData?.sellerId?.whatsappNumber,
        })
      );
      const userEmailData = JSON.stringify({
        countryCode: countryCode12,
        whatsappNo: phoneNumber12,
        Name: emailInq?.userName,
        Email: emailInq?.customerEmail,
        // message: emailInq?.message,
      });

      setUserName(userEmailData);
      // dispatch(allapiAction.getsubsubcatehome({}));
      setShowEmailIn(false);
      setShowView(false);

      return () => {};
    }
  };

  useEffect(() => {
    const blogIds = localStorage.getItem("access_name");
    const blogIdss = blogIds !== null ? blogIds : "{}";
    const arr = JSON.parse(blogIdss);

    const blogIdsnum = localStorage.getItem("access_number");
    const blogIdssnum = blogIdsnum !== null ? blogIdsnum : "{}";
    const arrnum = JSON.parse(blogIdssnum);

    setPhoneNumber("+" + arrnum?.countryCode + arrnum?.whatsappNo);
    setPhoneNumber2("+" + arr?.countryCode + arr?.whatsappNo);

    setCountryCode1(arrnum?.countryCode);
    setPhoneNumber1(arrnum?.whatsappNo);
    setCountryCode12(arr?.countryCode);
    setPhoneNumber12(arr?.whatsappNo);

    setwhatInq({
      name: arrnum?.name,
      email: arrnum?.email,
    });
    setemailInq({
      // message: arr?.message,
      customerEmail: arr?.Email,
      userName: arr?.Name,
    });
  }, []);

  // end

  let pageUrl;

  if (typeof window !== "undefined") {
    if (window.location !== undefined) {
      // Outputs: 'https:'

      pageUrl = window.location.href;
    }
  }

  // console.log(get_seller_ticket_list_public?.cate);

  // ogcurl
  // ogctitle
  // ogcimg
  // ogcdesc
  const urltet = URL.API_BASE_URL_2 + "/products?";

  const [windowWidth, setWindowWidth] = useState(600);

  // Update window width when the window is resized
  useLayoutEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Determine the width and height based on screen width

  function isWebView() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Example for Android WebView (check for "wv")
    if (/wv/.test(userAgent) || /Android.*WebView/.test(userAgent)) {
      return true;
    }

    // Example for iOS WebView (check for "iPhone OS" without "Safari")
    if (/iPhone|iPod|iPad/.test(userAgent) && !/Safari/.test(userAgent)) {
      return true;
    }

    return false; // Assume it's a browser if no WebView signature is detected
  }

  if (isWebView()) {
    console.log("Running inside a WebView");
  } else {
    console.log("Running inside a browser");
  }
  const imageWidth = windowWidth <= 767 ? 150 : 300;
  const imageHeight = windowWidth <= 767 ? (isWebView() ? 210 : 150) : 229;
  return (
    <div className={isWebView() ? "AppPro" : ""}>
      {/* <ScrollToTop /> */}
      <HeaderMain />
      {/* 
        <Seo
          pagLink={pageUrl}
          ogptitle={get_seller_ticket_list_public?.cate?.meta_title}
          seo_descriptions={get_seller_ticket_list_public?.cate?.meta_description}
          ogpimg={URL.API_BASE_URL + get_seller_ticket_list_public?.cate?.image}
          title={get_seller_ticket_list_public?.cate?.name}
          // titleSeo={get_page_details?.metaTag}
          // seo_descriptions={get_page_details?.metadescription}
        /> */}

      {/* <Head>
          <title>{"products -" + id}</title>
          <meta name="description" content="Generated by create next app" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
  
          <link rel="canonical" href={urltet + id} />
  
          <meta name="title" content={id} />
          <meta name="descriptions" content={id} />
        
  
          <meta property="og:locale" content="en" />
          <meta property="og:url" content={urltet + id} />
          <meta property="og:site_name" content="Jewelslly" />
          <meta property="og:title" content={post?.cate?.meta_title} />
          <meta
            property="og:description"
            content={post?.cate?.meta_description}
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content={URL.API_BASE_URL + post?.cate?.image}
          />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@jewelslly1" />
          <meta name="twitter:title" content={id} />
          <meta name="twitter:description" content={id} />
          <meta
            name="twitter:image"
            content={URL.API_BASE_URL + post?.cate?.image}
          />
          <meta name="twitter:image:alt" content={id} />
          
        </Head> */}

      <Modal show={showView} size="xl" onHide={handleCloseView}>
        <Modal.Header closeButton>
          <Modal.Title> Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading1 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "400px",
              }}
            >
              <BounceLoader color="#fd9b57" />
            </div>
          ) : (
            <div className="modal-content">
              {/* <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button> */}
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6 col-sm-12 col-xs-12 mb-md-0 mb-sm-5">
                    <div className="productPage__left">
                      <div className="productPage__sideImagesContainer">
                        {productImage &&
                          productImage?.map((item, index) => {
                            const src = item.match("undefined")
                              ? "/assets/imgs/notfound.png"
                              : item;
                            const isValidUrl =
                              src && /^(https?:\/\/)/i.test(src);

                            return (
                              <div
                                key={index}
                                onClick={() => setDispImg(item)}
                                className="sideImage"
                                // style={{
                                //   backgroundImage: `url(${
                                //     URL.API_BASE_URL + item
                                //   })`,
                                // }}
                              >
                                <Image
                                  src={src}
                                  alt={productData?.title + index}
                                  width={300}
                                  height={250}
                                  // onError={(e) => {
                                  //   e.target.onerror = null;
                                  //   e.target.src = "/assets/imgs/notfound.png";
                                  // }}
                                />
                              </div>
                            );
                          })}
                      </div>
                      <div className="productPage__displayImageContainer">
                        <div
                          className="productPage__displayImage"
                          // style={{
                          //   backgroundImage: `url(${
                          //     URL.API_BASE_URL + dispImg
                          //   })`,
                          // }}
                        >
                          <div style={{ width: "250px" }}>
                            <img
                              src={dispImg}
                              alt=""
                              style={{ width: "100%" }}
                            />
                          </div>
                          {/* <ReactImageMagnify
                              {...{
                                smallImage: {
                                  alt: "Wristwatch by Ted Baker London",
                                  isFluidWidth: true,
                                  src: dispImg,
                                  // sizes:
                                  //   "(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px",
                                },
                                largeImage: {
                                  src: dispImg,
                                  width: 1200,
                                  height: 1800,
                                },
                                // className: "imgViewS",
                                // imageStyle: {
                                //   maxHeight: "400px",
                                //   maxWidth: "100%",
                                //   width: "auto ",
                                //   margin: "auto",
                                // },
                                imageClassName: "imgViewS1",
                                // enlargedImageContainerDimensions: {
                                //   width: "200%",
                                //   height: "100%",
                                // },
                                enlargedImageStyle: {
                                  width: "50px",
                                },
                                lensStyle: {
                                  width: "20px",
                                  height: "20px",
                                },
                                shouldUsePositiveSpaceLens: true,
                                shouldHideHintAfterFirstActivation: false,
                                // isHintEnabled: true,
                                lensStyle: {
                                  backgroundColor: "rgba(0,0,0,.6)",
                                },
                                enlargedImagePosition: "over",
                              }}
                            /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 col-xs-12 ">
                    <div className="detail-info pr-30 pl-30">
                      {/* <span className="stock-status out-stock"> Sale Off </span> */}
                      <h3 className="title-detail mb-30">
                        <a
                          // href=""
                          className="text-heading"
                        >
                          {productData?.title}
                        </a>
                      </h3>

                      {/* <div className="clearfix product-price-cover">
                          <div className="product-price primary-color float-left">
                            <span className="current-price text-brand">$38</span>
                            <span>
                              <span className="save-price font-md color3 ml-15">
                                26% Off
                              </span>
                              <span className="old-price font-md ml-15">$52</span>
                            </span>
                          </div>
                        </div> */}
                      <div className="detail-extralink mb-30">
                        {/* <div className="detail-qty border radius">
                            <a href="#" className="qty-down">
                              <i className="fi-rs-angle-small-down"></i>
                            </a>
                            <span className="qty-val">1</span>
                            <a href="#" className="qty-up">
                              <i className="fi-rs-angle-small-up"></i>
                            </a>
                          </div> */}
                        {/* <div className="product-extra-link2 mt-3">
                          <button
                            type="submit"
                            className="button button-add-to-cart"
                          >
                         
                            Email
                          </button>
                        </div>
                        <div className="product-extra-link2 mt-3 ms-2">
                          <button
                            type="submit"
                            className="button button-add-to-cart"
                          >
                         
                            Whatsapp
                          </button>
                        </div> */}
                      </div>
                      <div className="font-xs">
                        <ul>
                          <li className="mb-5">
                            Tag:{" "}
                            <span className="text-brand">
                              {productData?.tegs}, {productData?.tegs2}
                            </span>
                          </li>
                          <li className="mb-5">
                            Category:
                            <span className="text-brand">
                              {" "}
                              {productData?.categoryId?.name}
                            </span>
                          </li>
                          {/* <li className="mb-5">
                            Status:
                            <span className="text-brand">
                              {" "}
                              {productData?.status}
                            </span>
                          </li> */}
                          <li className="mb-5">
                            Description:
                            {/* <span className="text-brand"> */}{" "}
                            <div
                              className=" modalBoXContaint"
                              dangerouslySetInnerHTML={{
                                __html: productData?.description,
                              }}
                            ></div>
                            {/* {productData?.description} */}
                            {/* </span> */}
                          </li>
                        </ul>
                      </div>
                      {/* <div dangerouslySetInnerHTML={{ __html: <p>dsfdsf</p> }}> </div> */}
                      {/* <div
                        className=""
                        dangerouslySetInnerHTML={{
                          __html: productData?.description,
                        }}
                      ></div> */}
                      <div className="product-extra-link2 ProBtn">
                        <Button variant="success" onClick={handleOpneEmailIn}>
                          Email
                        </Button>{" "}
                        {productData?.sellerId?.whatsappVerify && (
                          <Button
                            variant="success"
                            onClick={handleOpneWhatsappIn}
                          >
                            Whatsapp
                          </Button>
                        )}
                        {/* <button
                          type="submit"
                          className="button button-add-to-cart zindexne"
                        >
                          <i className="fi-rs-shopping-cart"></i>
                          Email
                        </button>
                        <button
                          type="submit"
                          className="button button-add-to-cart zindexne ms-3"
                        >
                          <i className="fi-rs-shopping-cart"></i>
                          Whatsapp
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* </div> */}
          {/* </div> */}
        </Modal.Body>
        {/* <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseView}>
                No
              </Button>
              <Button variant="primary" onClick={hendleDelete}>
                Yes
              </Button>
            </Modal.Footer> */}
      </Modal>

      {/* whatsapp model  */}
      <Modal show={showWhatsappIn} onHide={handleCloseWhatsappIn}>
        <Modal.Header closeButton>
          <Modal.Title>Whatsapp Inquiry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="card mx-auto ">
            <div className="card-body">
              {/* <h4 className="card-title mb-4">Feature Image</h4> */}

              {/* <div className="mb-3">
                  <label for="title" className="form-label">
                    Name
                  </label>
                  <input
                    name="userName"
                    className="form-control"
                    placeholder="Enter Your Name"
                    type="text"
                    onChange={(e) => {
                      hendleToWhatsapp(e);
                    }}
                  />
                </div> */}

              <div className="mb-3">
                <label for="teg" className="form-label">
                  Name
                </label>
                <input
                  name="name"
                  className="form-control"
                  placeholder="Enter name"
                  type="text"
                  value={whatInq?.name}
                  onChange={(e) => {
                    hendleToWhatsapp(e);
                  }}
                />
              </div>

              <div className="mb-3">
                <label for="teg" className="form-label">
                  Email
                </label>
                <input
                  name="email"
                  className="form-control"
                  placeholder="Enter Email"
                  type="email"
                  value={whatInq?.email}
                  onChange={(e) => {
                    hendleToWhatsapp(e);
                  }}
                />
              </div>
              <div className="mb-3">
                <label for="teg" className="form-label">
                  Number
                </label>
                <PhoneInput
                  placeholder="Enter phone number"
                  country="IN"
                  defaultCountry="IN"
                  value={phoneNumber}
                  onChange={handlePhoneChange}
                />
              </div>

              <div className="mb-3">
                <label for="teg" className="form-label">
                  Message
                </label>
                <textarea
                  name="message"
                  className="form-control"
                  placeholder="Enter message"
                  type="text"
                  value={whatInq?.message}
                  onChange={(e) => {
                    hendleToWhatsapp(e);
                  }}
                />
              </div>
              {/* <div className="mb-3">
                  <label for="teg" className="form-label">
                    Inquire
                  </label>
                  <textarea
                    name="message"
                    className="form-control"
                    placeholder="Enter Inquire"
                    type="text"
                    onChange={(e) => {
                      hendleToWhatsapp(e);
                    }}
                  />
                </div> */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseWhatsappIn}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              hendleWhatsappIn();
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* email model  */}
      <Modal show={showEmailIn} onHide={handleCloseEmailIn}>
        <Modal.Header closeButton>
          <Modal.Title>Email Inquiry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="card mx-auto ">
            <div className="card-body">
              {/* <h4 className="card-title mb-4">Feature Image</h4> */}

              {/* <div className="mb-3">
                  <label for="title" className="form-label">
                    Name
                  </label>
                  <input
                    name="userName"
                    className="form-control"
                    placeholder="Enter Your Name"
                    type="text"
                    onChange={(e) => {
                      hendleToWhatsapp(e);
                    }}
                  />
                </div> */}

              <div className="mb-3">
                <label for="teg" className="form-label">
                  Your Name
                </label>
                <input
                  name="userName"
                  className="form-control"
                  placeholder="Enter Name"
                  type="text"
                  value={emailInq?.userName}
                  onChange={(e) => {
                    hendleToEmail(e);
                  }}
                />
              </div>

              <div className="mb-3">
                <label for="teg" className="form-label">
                  Email
                </label>
                <input
                  name="customerEmail"
                  className="form-control"
                  placeholder="Enter Email"
                  type="text"
                  value={emailInq?.customerEmail}
                  onChange={(e) => {
                    hendleToEmail(e);
                  }}
                />
              </div>

              <div className="mb-3">
                <label for="teg" className="form-label">
                  Number
                </label>
                <PhoneInput
                  placeholder="Enter phone number"
                  country="IN"
                  defaultCountry="IN"
                  value={phoneNumber2}
                  onChange={handlePhoneChange2}
                />
              </div>
              <div className="mb-3">
                <label for="teg" className="form-label">
                  Message
                </label>
                <textarea
                  name="message"
                  className="form-control"
                  placeholder="Enter Message"
                  type="text"
                  value={emailInq?.message}
                  onChange={(e) => {
                    hendleToEmail(e);
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEmailIn}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              hendleEmailIn();
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <main className="main">
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="row justify-content-between">
              <div className="breadcrumb col">
                {/* <a href="index.html" rel="nofollow">
                  <i className="fi-rs-home mr-5"></i>home
                </a> */}
                <Link to={"/"}>
                  {" "}
                  <i className="fi-rs-home mr-5"></i>home
                </Link>
                <span> </span> {post?.cate?.name}
              </div>

              <div className=" col text-end">
                <h1 className="cateheading">{post?.cate?.name}</h1>
              </div>
            </div>
            {/* <div className="breadcrumb">
              
                <Link href={"/"}>
                  {" "}
                  <i className="fi-rs-home mr-5"></i>home
                </Link>
                <span> {post?.cate?.name}</span>
              </div> */}
          </div>
        </div>
        <div className="container mb-30">
          <div className="row flex-row-reverse">
            <div className="">
              {loading ? (
                ""
              ) : (
                <div className="shop-product-fillter">
                  <div className="totall-product">
                    <p>
                      We found{" "}
                      <strong className="text-brand">
                        {get_seller_ticket_list_public?.totalDocs}
                      </strong>{" "}
                      items for you!
                    </p>
                  </div>
                  <div className="sort-by-product-area"></div>
                </div>
              )}
              {get_seller_ticket_list_public?.totalDocs == 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "400px",
                  }}
                >
                  <h3>Record Not found</h3>
                </div>
              )}
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "400px",
                  }}
                >
                  <BounceLoader color="#fd9b57" />
                </div>
              ) : (
                <div className="row product-grid">
                  {/* {commentsData &&
                      commentsData?.map((data, i) => {
                        return (
                          <div
                            className="col-lg-1-5 col-md-4 col-12 col-sm-6"
                            key={i}
                          >
                            <div className="product-cart-wrap mb-30">
                              <div className="product-img-action-wrap">
                                <div className="product-img product-img-zoom">
                                  <Link
                                    href={"/product-details/" + data?.pro_sulg}
                                    onClick={() => {
                                      //   navigate(
                                      //     "/product-details/" + data?.pro_sulg
                                      //   );
                                      dispatch(
                                        allapiAction.productclick({
                                          productId: data?._id,
                                          categoryId: data?.categoryId?._id,
                                          sellerId: data?.sellerId?._id,
                                          type: "product",
                                        })
                                      );
                                    }}
                                  >
                                    <Image
                                      className="default-img"
                                      src={URL.API_BASE_URL + data?.featureImage}
                                      alt={data?.title}
                                      width={300}
                                      height={250}
                                      // onError={(e) => {
                                      //   e.target.onerror = null;
                                      //   e.target.src =
                                      //     "/assets/imgs/notfound.png";
                                      // }}
                                    />
                                    
                                  </Link>
                                </div>
                                <div className="product-action-1">
                                  <a
                                    aria-label="Quick view"
                                    className="action-btn"
                                    data-bs-toggle="modal"
                                    data-bs-target="#quickViewModal"
                                    onClick={() => {
                                      hendleModalShowView(data);
                                    }}
                                  >
                                    <i className="fi-rs-eye"></i>
                                  </a>
                                </div>
                              </div>
                              <div className="product-content-wrap">
                                <div className="product-category">
                                  <Link
                                    href={
                                      "/category-product/" +
                                      data?.categoryId?.cate_sulg
                                    }
                                    // onClick={() => {
                                    //   navigate(
                                    //     "/category-product/" +
                                    //       data?.categoryId?.cate_sulg
                                    //   );
                                    // }}
                                  >
                                    {data?.categoryId?.name}
                                  </Link>
                                </div>
                                <h2>
                                  <Link
                                    href={"/product-details/" + data?.pro_sulg}
                                    onClick={() => {
                                      //   navigate(
                                      //     "/product-details/" + data?.pro_sulg
                                      //   );
                                      dispatch(
                                        allapiAction.productclick({
                                          productId: data?._id,
                                          categoryId: data?.categoryId?._id,
                                          sellerId: data?.sellerId?._id,
                                          type: "product",
                                        })
                                      );
                                    }}
                                  >
                                    {data?.title}
                                  </Link>
                                </h2>
  
                                <div>
                                  <span className="font-small text-muted">
                                    By{" "}
                                    <Link
                                      href={
                                        "/shop-product/" +
                                        data?.sellerId?.buseniss_id?.slug
                                      }
                                      onClick={() => {
                                        // navigate(
                                        //   "/shop-product/" +
                                        //     data?.sellerId?.buseniss_id?.slug
                                        // );
                                      }}
                                    >
                                      {data?.sellerId?.buseniss_id?.businessName}
                                    </Link>
                                  </span>
                                </div>
                                {data?.sellerId?.country && (
                                  <div>
                                    <span className="font-small text-muted">
                                      <i className="fi-rs-marker mr-5"></i>
                                      
                                      <Link
                                        href={
                                          "/shops/" +
                                          data?.sellerId?.country
                                            .split(" ")
                                            .join("-")
                                        }
                                        onClick={() => {
                                          //   navigate(
                                          //     "/shops/" +
                                          //       data?.sellerId?.country
                                          //         .split(" ")
                                          //         .join("-")
                                          //   );
                                        }}
                                      >
                                        {data?.sellerId?.country}
                                      </Link>
                                    </span>
                                  </div>
                                )}
  
                                <div className="product-extra-link2 d-flex">
                                   
                                  <Button
                                    // variant="success"
                                    className="mt-3 add coustomP"
                                    onClick={() => {
                                      hendleModalEmailView(data);
                                      dispatch(
                                        allapiAction.productclick({
                                          productId: data?._id,
                                          categoryId: data?.categoryId?._id,
                                          sellerId: data?.sellerId?._id,
                                          type: "email",
                                        })
                                      );
                                    }}
                                  >
                                    Email
                                  </Button>{" "}
                              
                                  {data?.sellerId?.whatsappVerify && (
                                    <Button
                                      className="mt-3 ms-2 add coustomP"
                                      // variant="success"
                                      onClick={() => {
                                        hendleModalWhatsappView(data);
                                        dispatch(
                                          allapiAction.productclick({
                                            productId: data?._id,
                                            categoryId: data?.categoryId?._id,
                                            sellerId: data?.sellerId?._id,
                                            type: "whatsapp",
                                          })
                                        );
                                      }}
                                      // onClick={hendleModalWhatsappView}
                                    >
                                      Whatsapp
                                    </Button>
                                  )}
                                
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })} */}
                  {commentsData &&
                    commentsData?.map((data, i) => {
                      return (
                        <div class="col-md-3 col-6" key={i}>
                          <div class="card" style={{ width: "auto" }}>
                            <div class="product-grid">
                              <div class="product-image">
                                <Link to={"/product-details/" + data?.pro_sulg}>
                                  {/* <img
                                      class="pic-1"
                                      src={URL.API_BASE_URL + data?.featureImage}
                                    /> */}
                                  <Image
                                    className="default-img"
                                    src={URL.API_BASE_URL + data?.featureImage}
                                    alt={data?.title}
                                    width={imageWidth}
                                    height={imageHeight}
                                    // onError={(e) => {
                                    //   e.target.onerror = null;
                                    //   e.target.src =
                                    //     "/assets/imgs/notfound.png";
                                    // }}
                                  />
                                </Link>
                              </div>

                              <div class="card-body text-left pb-2 p-0">
                                {/* <button
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "600",
                                    }}
                                    type="button"
                                    class="btn btn-outline-success  form-control btncccc"
                                  >
                                    Share
                                  
                                  </button> */}
                                <Link
                                  to={"/product-details/" + data?.pro_sulg}
                                  class="card-text text-secondary ml-1 menu m-0"
                                >
                                  {/* {data?.title} */}

                                  <TruncateText
                                    text={data?.title}
                                    maxLength={23}
                                  />
                                </Link>
                                <br />
                                <Link
                                  class="abg ml-1"
                                  to={
                                    "/category-product/" +
                                    data?.categoryId?.cate_sulg
                                  }
                                >
                                  {" "}
                                  {data?.categoryId?.name}
                                </Link>
                                <br />
                                {value && (
                                  <p class="p-0 m-0 act ml-1">
                                    ₹ &nbsp;{data?.afterdiscountprice}
                                    <i class="fa fa-podcast"></i>
                                  </p>
                                )}
                                {value && (
                                  <span class="act1 float-right">
                                    ₹ {data?.price}&nbsp;
                                  </span>
                                )}
                              </div>
                              {/* <ul class="social">
                            <li>
                              <a href="" data-tip="Quick View">
                                <i class="fa fa-eye"></i>
                              </a>
                            </li>
                            <li>
                              <a href="" data-tip="Wishlist">
                                <i class="fa fa-heart"></i>
                              </a>
                            </li>
                            <li>
                              <a href="" data-tip="add to cart">
                                <i class="fa fa-shopping-cart"></i>
                              </a>
                            </li>
                            <li>
                              <a href="" data-tip="Compare">
                                <i class="fa fa-random"></i>
                              </a>
                            </li>
                          </ul> */}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
              {/* <!--product grid--> */}
              <div className="pagination-area mt-20 mb-20">
                {commentsData?.length > 0 && (
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=" >"
                    className="dataTables_paginate paging_simple_numbers category"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={2}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    forcePage={page ? page - 1 : 0}
                  />
                )}
              </div>
            </div>
            {/* <div className="col-lg-1-5 primary-sidebar sticky-sidebar">
                <div className="sidebar-widget widget-category-2 mb-30">
                  <h5 className="section-title style-1 mb-30">Address</h5>
  
                  <div className="mb-3">
                    <label className="form-label">Select Country</label>
                    <Select
                      className="coustomSelect"
                      classNamePrefix="coustomSelect"
                      value={selectedOption}
                      onChange={setSelectedOption}
                      options={subList}
                    />
                  </div>
  
                  <div className="mb-3">
                    <label className="form-label">Select City</label>
                    <Select
                      className="coustomSelect"
                      classNamePrefix="coustomSelect"
                      value={selectedOptionCity}
                      onChange={setSelectedOptionCity}
                      options={subListCity}
                    />
                  </div>
  
                  <a
                    onClick={() => {
                      setSelectedOption(null);
                      setSelectedOptionCity(null);
                    }}
                    className="btn btn-xs mt-3 m"
                  >
                    Clear
                  </a>
                </div>
              </div> */}
          </div>
        </div>
      </main>
      <FooterMain />
    </div>
  );
}

export default Products;
