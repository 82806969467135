import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function Account() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div class="container mt-5 pt-4">
        <div class="row mt-5">
          <div class="col-12 d-flex justify-content-right">
            <div class="">
              <h1 class="fw-bold">
                Hey <br />
                There!
              </h1>
            </div>
            <div class="ms-auto ">
              <a href="#" class="d-flex justify-content-end border-5">
                <img
                  class="w-25 border-5 mt-1  "
                  src="assets/imgs/log in.png"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-12 d-flex align-items-center">
            <a
              class="text-decoration-none"
              href=""
              data-bs-toggle="modal"
              data-bs-target="#loginModal"
            >
              <h6 class="fw-bold main-text text-danger text-nowrap">
                Signup or Login →
              </h6>
            </a>
            <hr class="w-100 main-hr" />
          </div>
        </div>
      </div>
      <div class="container ">
        <div class="row mb-5 pb-4">
          <Link class="text-decoration-none text-dark" to="/home">
            <div class="d-flex justify-content-between">
              <div>
                <h6 class="fw-bold">Dashboard</h6>
                <p>Check your orders status (track, return, cancel etc)</p>
              </div>
              <div>
                <img
                  class="w-50 mt-3"
                  src="assets/imgs/icons8-order.gif"
                  alt=""
                />
              </div>
            </div>
          </Link>
          <hr />
          <a class="text-decoration-none text-dark" href="">
            <div class="d-flex justify-content-between">
              <div>
                <h6 class="fw-bold">Orders</h6>
                <p>Check your orders status (track, return, cancel etc)</p>
              </div>
              <div>
                <img
                  class="w-50 mt-3"
                  src="assets/imgs/icons8-order.gif"
                  alt=""
                />
              </div>
            </div>
          </a>
          <hr />

          <a class="text-decoration-none text-dark" href="">
            <div class="d-flex justify-content-between ">
              <div>
                <h6 class="fw-bold">Coupons</h6>
                <p>browse coupons to get discount on Nykaa</p>
              </div>
              <div>
                <img
                  class="w-50 mt-3"
                  src="assets/imgs/icons8-coupon-50.png"
                  alt=""
                />
              </div>
            </div>
          </a>
          <hr />

          <a class="text-decoration-none text-dark" href="">
            <div class="d-flex justify-content-between">
              <div>
                <h6 class="fw-bold">Wallet</h6>
                <p>Login to check your wallet balance</p>
              </div>
              <div>
                <img
                  class="w-50 mt-3"
                  src="assets/imgs/icons8-wallet.gif"
                  alt=""
                />
              </div>
            </div>
          </a>
          <hr />

          <a class="text-decoration-none text-dark" href="">
            <div class="d-flex justify-content-between">
              <div>
                <h6 class="fw-bold">Nykaa Prive</h6>
                <p>Shop for ₹2000 to become a member</p>
              </div>
              <div>
                <img
                  class="w-50 mt-3"
                  src="assets/imgs/icons8-fairytale-50.png"
                  alt=""
                />
              </div>
            </div>
          </a>
          <hr />

          <a class="text-decoration-none text-dark" href="">
            <div class="d-flex justify-content-between">
              <div>
                <h6 class="fw-bold">Beauty Portfolio</h6>
                <p>Personalise your Nykaa App experience</p>
              </div>
              <div>
                <img
                  class="w-50 mt-3"
                  src="assets/imgs/icons8-portfolio-50.png"
                  alt=""
                />
              </div>
            </div>
          </a>
          <hr />

          <a class="text-decoration-none text-dark" href="">
            <div class="d-flex justify-content-between">
              <div>
                <h6 class="fw-bold">Wishlist</h6>
                <p>Login too view items in Wishlist </p>
              </div>
              <div>
                <img
                  class="w-50 mt-3"
                  src="assets/imgs/icons8-heart-50.png"
                  alt=""
                />
              </div>
            </div>
          </a>
          <hr />

          <a class="text-decoration-none text-dark" href="">
            <div class="d-flex justify-content-between">
              <div>
                <h6 class="fw-bold">Help Center</h6>
                <p>FAQ, Live Chat, Customer Support</p>
              </div>
              <div>
                <img
                  class="w-50 mt-3"
                  src="assets/imgs/icons8-help.gif"
                  alt=""
                />
              </div>
            </div>
          </a>
          <hr />

          <a class="text-decoration-none text-dark" href="">
            <div class="d-flex justify-content-between">
              <div>
                <h6 class="fw-bold">Addresses</h6>
                <p>Manage your saved addressses</p>
              </div>
              <div>
                <img
                  class="w-50 mt-3"
                  src="assets/imgs/icons8-address.gif"
                  alt=""
                />
              </div>
            </div>
          </a>
          <hr />

          <a class="text-decoration-none text-dark" href="">
            <div class="d-flex justify-content-between">
              <div>
                <h6 class="fw-bold">Payment Methods</h6>
                <p>Manage your saved payment methods</p>
              </div>
              <div>
                <img
                  class="w-50 mt-3"
                  src="assets/imgs/icons8-atm-card-50.png"
                  alt=""
                />
              </div>
            </div>
          </a>
          <hr />

          <a class="text-decoration-none text-dark" href="">
            <div class="d-flex justify-content-between">
              <div>
                <h6 class="fw-bold">Profile Settings</h6>
                <p>Login to change your profile settings</p>
              </div>
              <div>
                <img
                  class="w-50 mt-3"
                  src="assets/imgs/icons8-account.gif"
                  alt=""
                />
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Account;
