// import { allapiAction } from "@/Redux/common/action";
// import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
// import dynamic from "next/dynamic";

// import Sidebar from "../Layout/Sidebar";
// import { ProSidebarProvider } from "react-pro-sidebar";
// import Header from "../Layout/Header";
// import AreaGrap from "../Graph/AreaGrap";
// import Footer from "../Layout/Footer";
import { useDispatch, useSelector } from "react-redux";
// import AreaGrap from "../../../component/Graph/AreaGrap";
// import Footerr from "../../../component/sidebar/Footerr";
// import Header2 from "../../../component/sidebar/Header2";
// import Sidebar from "../../../component/sidebar/Sidebar";
import BounceLoader from "react-spinners/BounceLoader";
// import Api from "@/Redux/common/api";
import moment from "moment";
// import ProgressBar from "../../../component/Home/ProgressBar";
import Chart from "react-google-charts";
// import { URL } from "@/Redux/common/url";
import { AsyncPaginate } from "react-select-async-paginate";
import Sidebar from "../Layout/Sidebar";
import HeaderAccount from "../Layout/HeaderAccount";
import { allapiAction } from "../../Redux/common/action";
import { toast } from "react-toastify";
import { URL } from "../../Redux/common/url";
import Confetti from "./Confetti";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
// import { allapiAction } from "../../../Redux/common/action";

// AreaGrap

// import Cktest from "../../../component/cktetst/Cktest";
// const AreaGrap = dynamic(() => import("../../../component/Graph/AreaGrap"), {
//   ssr: false,
// });

function DashBoardApp() {
  const dispatch = useDispatch();

  const deshboard = useSelector((state) =>
    state?.allapi?.deshboard_state ? state?.allapi?.deshboard_state : {}
  );

  console.log(deshboard);
  const team_count = useSelector((state) =>
    state?.allapi?.team_count ? state?.allapi?.team_count : {}
  );
  const top_three = useSelector((state) =>
    state?.allapi?.top_three ? state?.allapi?.top_three : {}
  );
  const get_leader_board_c_m = useSelector((state) =>
    state?.allapi?.get_leader_board_c_m
      ? state?.allapi?.get_leader_board_c_m
      : {}
  );

  // console.log(
  //   "top_threetop_threetop_threetop_threetop_threetop_threetop_threetop_threetop_threetop_threetop_three",
  //   top_three
  // );
  // console.log(
  //   "top_threetop_threetop_threetop_threetop_threetop_threetop_threetop_threetop_threetop_threetop_three",
  //   deshboard?.team?.todaybuss
  // );
  const week_data = useSelector((state) =>
    state?.allapi?.week_data ? state?.allapi?.week_data : []
  );
  const day_data = useSelector((state) =>
    state?.allapi?.day_data ? state?.allapi?.day_data : []
  );
  const get_last_three_month = useSelector((state) =>
    state?.allapi?.get_last_three_month
      ? state?.allapi?.get_last_three_month
      : []
  );
  const lead_achiver = useSelector((state) =>
    state?.allapi?.lead_achiver ? state?.allapi?.lead_achiver : []
  );
  // const loader = useSelector((state) =>
  //   state?.allapi?.loader ? state?.allapi?.loader : false
  // );

  const seller_update_details = useSelector((state) =>
    state?.allapi?.seller_update_details
      ? state?.allapi?.seller_update_details
      : {}
  );

  console.log(seller_update_details);

  console.log(get_last_three_month);

  const [loader, setfirst] = useState(true);

  const [lisshowcount, setlisscount] = useState("");
  const [managerKey, setManagerKey] = useState(0);
  // const [userselete, setuserselete] = useState(null);

  //   const route = useRouter();

  useEffect(() => {
    // if (!localStorage.getItem("access_token")) {

    //   route.push("/login");

    // }
    setTimeout(() => {
      setfirst(false);
    }, 1000);
  }, []);

  useEffect(() => {
    // sellerDashboardState
    dispatch(allapiAction.weekWiseSale({}));
    dispatch(allapiAction.dayWiseSale({}));
    dispatch(allapiAction.myTeamCount({}));
    dispatch(allapiAction.LeaderBoard({}));
    // dispatch(allapiAction.LeaderBoardbyAdmin({}));
    dispatch(allapiAction.LedAchiversList({}));
    dispatch(allapiAction.getuserBussnisslastthreeMonth2({}));

    dispatch(allapiAction.getsellerprofilebyseller({}));
    return () => {};
  }, []);

  const profile = useSelector((state) =>
    state?.allapi?.get_profile ? state?.allapi?.get_profile : {}
  );
  const user_pre_month_commission = useSelector((state) =>
    state?.allapi?.user_pre_month_commission
      ? state?.allapi?.user_pre_month_commission
      : {}
  );
  console.log(user_pre_month_commission);

  useEffect(() => {
    dispatch(allapiAction.getuserBussnissMonth({}));
    dispatch(allapiAction.userCommissionpreviousmonth({}));
    // dispatch(allapiAction.getuserdeshboardData({}));
    // dispatch(allapiAction.getprofile({}));
    // dispatch(allapiAction.sellerDirectTeam({}));

    return () => {};
  }, []);

  // const loadOptionsPalyer = async (search, loadedOptions, page) => {
  //   const response = await fetch(
  //     URL.API_BASE_URL +
  //       `/publicApi/getuserforlinsense?search=${search}&page=${page}&user_id=${profile?.referId}`
  //   );
  //   const responseJSON = await response.json();

  //   const options = responseJSON?.results?.map((data) => {
  //     data.value = data?.id;
  //     data.label = data?.fullName;

  //     return data;
  //   });

  //   return {
  //     options: options,
  //     hasMore: responseJSON.has_more,
  //     additional: {
  //       page: page + 1,
  //     },
  //   };
  // };

  function getMonthList() {
    const startDate = new Date("2021-08-01");
    const currentDate = new Date();

    // Calculate the month one less than the current month
    currentDate.setMonth(currentDate.getMonth() - 1);

    const monthsList = [];

    while (startDate <= currentDate) {
      monthsList.push(
        startDate.toLocaleString("default", { month: "short", year: "numeric" })
      );

      startDate.setMonth(startDate.getMonth() + 1);
    }

    return monthsList;
  }

  // useEffect(() => {
  //   const months = getMonthList();
  //   console.log(months);
  // }, []);
  const options = {
    title: "Company Performance",
    curveType: "function",
    legend: { position: "bottom" },
  };

  const [tab, setTab] = useState("day");
  const proStatus =
    seller_update_details?.status == 1
      ? 0
      : seller_update_details?.status == 2
      ? 25
      : seller_update_details?.status == 3
      ? 50
      : seller_update_details?.status == 4
      ? 75
      : seller_update_details?.status == 5
      ? 100
      : 100;

  const [userselete, setuserselete] = useState(null);
  const [userselete2, setuserselete2] = useState(null);
  const [userselete3, setuserselete3] = useState(null);
  const [userselete4, setuserselete4] = useState(null);
  const loadOptionsPalyer = async (search, loadedOptions, pages) => {
    console.log(loadedOptions);
    console.log(pages);
    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/getuserforlinsense?search=${search}&page=${pages?.page}&user_id=${seller_update_details?.referId}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?.id;
      data.label = data?.referId + " - " + data?.fullName;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: pages?.page + 1,
      },
    };
  };

  const hendletoAssLicence = async (lisshowcount) => {
    // addlicence

    const userLicense =
      lisshowcount == 1
        ? seller_update_details?.Userchaindata?.license1
        : lisshowcount == 2
        ? seller_update_details?.Userchaindata?.license2
        : lisshowcount == 3
        ? seller_update_details?.Userchaindata?.license3
        : seller_update_details?.Userchaindata?.license4;

    if (!lisshowcount) {
      toast.error("please select license");
    } else if (!userLicense) {
      toast.error("already used");
    } else if (!userselete) {
      toast.error("please select user");
    } else {
      const donedata = await dispatch(
        allapiAction.addlicenceUser({
          userIdFrom: seller_update_details?._id,
          userIdTo: userselete?._id,
          licence: userLicense,
          licenceno: lisshowcount,
        })
      );

      if (donedata?.success) {
        dispatch(allapiAction.getsellerprofilebyseller({}));
      }

      // setstatusLicence(false);
    }
  };
  const hendletoAssLicence2 = async (lisshowcount) => {
    // addlicence

    const userLicense =
      lisshowcount == 1
        ? seller_update_details?.Userchaindata?.license1
        : lisshowcount == 2
        ? seller_update_details?.Userchaindata?.license2
        : lisshowcount == 3
        ? seller_update_details?.Userchaindata?.license3
        : seller_update_details?.Userchaindata?.license4;

    if (!lisshowcount) {
      toast.error("please select license");
    } else if (!userLicense) {
      toast.error("already used");
    } else if (!userselete2) {
      toast.error("please select user");
    } else {
      const donedata = await dispatch(
        allapiAction.addlicenceUser({
          userIdFrom: seller_update_details?._id,
          userIdTo: userselete2?._id,
          licence: userLicense,
          licenceno: lisshowcount,
        })
      );
      if (donedata?.success) {
        dispatch(allapiAction.getsellerprofilebyseller({}));
      }
      // setstatusLicence(false);
    }
  };
  const hendletoAssLicence3 = async (lisshowcount) => {
    // addlicence

    const userLicense =
      lisshowcount == 1
        ? seller_update_details?.Userchaindata?.license1
        : lisshowcount == 2
        ? seller_update_details?.Userchaindata?.license2
        : lisshowcount == 3
        ? seller_update_details?.Userchaindata?.license3
        : seller_update_details?.Userchaindata?.license4;

    if (!lisshowcount) {
      toast.error("please select license");
    } else if (!userLicense) {
      toast.error("already used");
    } else if (!userselete3) {
      toast.error("please select user");
    } else {
      const donedata = await dispatch(
        allapiAction.addlicenceUser({
          userIdFrom: seller_update_details?._id,
          userIdTo: userselete3?._id,
          licence: userLicense,
          licenceno: lisshowcount,
        })
      );

      // console.log(donedata);
      if (donedata?.success) {
        dispatch(allapiAction.getsellerprofilebyseller({}));
      }
      // setstatusLicence(false);
    }
  };
  const hendletoAssLicence4 = async (lisshowcount) => {
    // addlicence

    const userLicense =
      lisshowcount == 1
        ? seller_update_details?.Userchaindata?.license1
        : lisshowcount == 2
        ? seller_update_details?.Userchaindata?.license2
        : lisshowcount == 3
        ? seller_update_details?.Userchaindata?.license3
        : seller_update_details?.Userchaindata?.license4;

    if (!lisshowcount) {
      toast.error("please select license");
    } else if (!userLicense) {
      toast.error("already used");
    } else if (!userselete4) {
      toast.error("please select user");
    } else {
      const donedata = await dispatch(
        allapiAction.addlicenceUser({
          userIdFrom: seller_update_details?._id,
          userIdTo: userselete4?._id,
          licence: userLicense,
          licenceno: lisshowcount,
        })
      );
      if (donedata?.success) {
        dispatch(allapiAction.getsellerprofilebyseller({}));
      }
      // setstatusLicence(false);
    }
  };

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
  };
  const router = useNavigate();
  return (
    <div className="AppPro">
      {loader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <BounceLoader color="#0D6EFD" />
        </div>
      ) : (
        <div>
          <Sidebar />

          <main className="main-wrap">
            <HeaderAccount />

            <section className="content-main">
              {/* <Confetti /> */}
              <div className="content-header">
                <div>
                  <h2 className="content-title card-title">Dashboard</h2>
                </div>
              </div>

              {/* <div className="row">
                <div className="col-lg-12">
                  <div id="profile_content">
                    <div class="row">
                      <div
                        class="col-md-12 dashboard-hides"
                        style={{ display: "none" }}
                      >
                        <i class="fa fa-spinner"></i>
                      </div>

                      <div
                        class="col-md-12 dashboard-shows"
                        style={{ marginTop: "15px" }}
                      >
                        <div class="row mb-5"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              <div class="container">
                <Slider {...settings}>
                  <div className="p-1">
                    <div class="card1">
                      <div>
                        <span style={{ color: "rgb(24, 130, 178)" }}>
                          Commission
                        </span>
                      </div>

                      <h4 class="mt-5">
                        {user_pre_month_commission?.commission ? (
                          <b>{user_pre_month_commission?.commission}</b>
                        ) : (
                          <b>0</b>
                        )}
                      </h4>
                      <span>Commission amount</span>
                    </div>
                  </div>
                  <div className="p-1">
                    {" "}
                    <div class="card2">
                      <div>
                        <span style={{ color: "rgb(69, 178, 150)" }}>
                          Licenses
                        </span>
                      </div>

                      <h4 class="mt-5">
                        {seller_update_details?.userlicence == "1" ||
                        seller_update_details?.addlicenseAdmin == "1" ? (
                          <b>4</b>
                        ) : (
                          <b>0</b>
                        )}
                      </h4>
                      <span> Total licenses</span>
                    </div>
                  </div>
                  <div className="p-1">
                    {" "}
                    <div
                      class="card3 "
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        router("/home/orderbusiness");
                      }}
                    >
                      <div>
                        <span style={{ color: "rgb(250, 149, 50)" }}>
                          Business Volume
                        </span>
                      </div>

                      <h4 class="mt-5">
                        <b>{deshboard?.team?.todaybuss}</b>
                      </h4>
                      <span>Daily</span>
                    </div>
                  </div>
                  <div className="p-1">
                    {" "}
                    <div class="card4">
                      <div>
                        <span style={{ color: "rgb(69, 178, 150)" }}>Team</span>
                      </div>

                      <h4 class="mt-5">
                        <b>{deshboard?.team?.allTeam}</b>
                      </h4>
                      <span> Total Team</span>
                    </div>
                  </div>
                  <div className="p-1">
                    {" "}
                    <div class="card5">
                      <div>
                        <span style={{ color: "rgb(69, 178, 150)" }}>Team</span>
                      </div>

                      <h4 class="mt-5">
                        <b>{deshboard?.team?.DirectTeam}</b>
                      </h4>
                      <span> Direct Team </span>
                    </div>
                  </div>
                  <div className="p-1">
                    {" "}
                    <div class="card5">
                      <div>
                        <span style={{ color: "rgb(69, 178, 150)" }}>
                          Wallet
                        </span>
                      </div>

                      <h4 class="mt-5">
                        <b>
                          {seller_update_details?.balance
                            ? seller_update_details?.balance?.toFixed(2)
                            : 0}
                        </b>
                      </h4>
                      <span> Wallet </span>
                    </div>
                  </div>
                  <div className="p-1">
                    {" "}
                    <div
                      class="card3 "
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        router("/home/orderbusiness");
                      }}
                    >
                      <div>
                        <span style={{ color: "rgb(250, 149, 50)" }}>
                          {deshboard &&
                            moment(deshboard?.buss?.curM?.month).format(
                              "MMM yyyy "
                            )}{" "}
                        </span>
                      </div>

                      <h4 class="mt-5">
                        <b> {deshboard?.buss?.curM?.amount}</b>
                      </h4>
                      <span> Business </span>
                    </div>
                  </div>
                </Slider>
                {/* <div class="row">
                  <div class="col-6">
                    <div class="card1">
                      <div>
                        <span style={{ color: "rgb(24, 130, 178)" }}>
                          Commission
                        </span>
                      </div>

                      <h4 class="mt-5">
                        {user_pre_month_commission?.commission ? (
                          <b>{user_pre_month_commission?.commission}</b>
                        ) : (
                          <b>0</b>
                        )}
                      </h4>
                      <span>Commission amount</span>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="card2">
                      <div>
                        <span style={{ color: "rgb(69, 178, 150)" }}>
                          Licenses
                        </span>
                      </div>

                      <h4 class="mt-5">
                        {seller_update_details?.userlicence == "1" ||
                        seller_update_details?.addlicenseAdmin == "1" ? (
                          <b>4</b>
                        ) : (
                          <b>0</b>
                        )}
                      </h4>
                      <span> Total licenses</span>
                    </div>
                  </div>
                  <div class="col-6">
                    <div
                      class="card3 "
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        router("/home/orderbusiness");
                      }}
                    >
                      <div>
                        <span style={{ color: "rgb(250, 149, 50)" }}>
                          Business Volume
                        </span>
                      </div>

                      <h4 class="mt-5">
                        <b>{deshboard?.team?.todaybuss}</b>
                      </h4>
                      <span>Daily</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="card4">
                      <div>
                        <span style={{ color: "rgb(69, 178, 150)" }}>Team</span>
                      </div>

                      <h4 class="mt-5">
                        <b>{deshboard?.team?.allTeam}</b>
                      </h4>
                      <span> Total Team</span>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="card5">
                      <div>
                        <span style={{ color: "rgb(69, 178, 150)" }}>Team</span>
                      </div>

                      <h4 class="mt-5">
                        <b>{deshboard?.team?.DirectTeam}</b>
                      </h4>
                      <span> Direct Team </span>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="card5">
                      <div>
                        <span style={{ color: "rgb(69, 178, 150)" }}>
                          Wallet
                        </span>
                      </div>

                      <h4 class="mt-5">
                        <b>{seller_update_details?.balance}</b>
                      </h4>
                      <span> Wallet </span>
                    </div>
                  </div>
                </div> */}
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div id="profile_content">
                    <div class="row">
                      <div
                        class="col-md-12 dashboard-hides"
                        style={{ display: "none" }}
                      >
                        <i class="fa fa-spinner"></i>
                      </div>

                      {/* <div
                        class="col-md-12 dashboard-shows"
                        style={{ marginTop: "15px" }}
                      >
                        <div class="row mb-5">
                          <div class="col-md-6">
                            <ul
                              class="profile_ul"
                              style={{ background: "blue" }}
                            >
                              <li>
                                <a href="#">
                                  <b
                                    style={{ fontSize: "31px", color: "white" }}
                                  >
                                    <i class="fa fa-user"></i>{" "}
                                    {deshboard?.team?.allTeam}{" "}
                                  </b>
                                </a>
                              </li>
                              <a class="pnav_my_team" href="#">
                                <li style={{ textAlign: "right" }}>
                                  <b style={{ color: "white" }}>TOTAL USER</b>
                                </li>
                              </a>
                            </ul>
                          </div>

                          <div class="col-md-6">
                            <ul
                              class="profile_ul"
                              style={{ background: "#d8d82f" }}
                            >
                              <li>
                                <a href="#">
                                  <b
                                    style={{ fontSize: "31px", color: "white" }}
                                  >
                                    <i class="fa fa-user"></i>
                                    {deshboard?.team?.DirectTeam}{" "}
                                  </b>
                                </a>
                              </li>
                              <a class="pnav_my_direct_team" href="#">
                                <li style={{ textAlign: "right" }}>
                                  <b style={{ color: "white" }}>MY DOWNLINE</b>
                                </li>
                              </a>
                            </ul>
                          </div>
                          {deshboard && (
                            <div class="col-md-6">
                              <ul
                                class="profile_ul"
                                style={{ background: "green" }}
                              >
                                <li>
                                  <a href="#">
                                    <b
                                      style={{
                                        fontSize: "31px",
                                        color: "white",
                                      }}
                                    >
                                      <i class="fa fa-inr"> </i>
                                      {deshboard?.buss?.curM?.amount}
                                    </b>
                                  </a>
                                </li>
                                <a class="pnav_my_direct_team" href="#">
                                  <li style={{ textAlign: "right" }}>
                                    <b style={{ color: "white" }}>
                                      {" "}
                                      {deshboard &&
                                        moment(
                                          deshboard?.buss?.curM?.month
                                        ).format("MMM yyyy ")}{" "}
                                      Business
                                    </b>
                                  </li>
                                </a>
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>

                      <div
                        class="col-md-12 top3seller-hides"
                        style={{ display: "none" }}
                      >
                        <i class="fa fa-spinner"></i>
                      </div> */}

                      <div
                        class="col-md-12 top3seller-shows"
                        style={{ marginTop: "15px" }}
                      >
                        <div class="row">
                          <div class="col-md-12">
                            <h3 class="block-title">
                              <span>Rank</span>
                            </h3>
                            <div
                              class="widget widget-categories "
                              style={{ paddingBottom: "25px" }}
                            >
                              <div className="profile_ul1">
                                <div class="container mb-5 mt-5 star_overflow">
                                  <div class="progress">
                                    <div
                                      class="progress-bar range"
                                      role="progressbar"
                                      style={{ width: proStatus + "%" }}
                                      aria-valuenow={proStatus}
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    ></div>
                                  </div>
                                  <div class="range_star d-flex justify-content-between">
                                    <img
                                      src={
                                        seller_update_details?.status >= 1
                                          ? "/assets/imgs/startyellow.jpg"
                                          : "/assets/imgs/startbrown.png"
                                      }
                                      class="rounded-circle"
                                      alt="star_icon"
                                    />
                                    <img
                                      src={
                                        seller_update_details?.status >= 2
                                          ? "/assets/imgs/startyellow.jpg"
                                          : "/assets/imgs/startbrown.png"
                                      }
                                      class="rounded-circle"
                                      alt="star_icon"
                                    />
                                    <img
                                      src={
                                        seller_update_details?.status >= 3
                                          ? "/assets/imgs/startyellow.jpg"
                                          : "/assets/imgs/startbrown.png"
                                      }
                                      class="rounded-circle"
                                      alt="star_icon"
                                    />
                                    <img
                                      src={
                                        seller_update_details?.status >= 4
                                          ? "/assets/imgs/startyellow.jpg"
                                          : "/assets/imgs/startbrown.png"
                                      }
                                      class="rounded-circle"
                                      alt="star_icon"
                                    />
                                    <img
                                      src={
                                        seller_update_details?.status >= 5
                                          ? "/assets/imgs/startyellow.jpg"
                                          : "/assets/imgs/startbrown.png"
                                      }
                                      class="rounded-circle"
                                      alt="star_icon"
                                    />
                                  </div>
                                  <div class="d-flex justify-content-between text-secondary">
                                    <small class="text-center star_p">
                                      <b>Fr</b>
                                      <br />
                                      {seller_update_details?.Userchaindata
                                        ?.fr_date &&
                                        moment(
                                          seller_update_details?.Userchaindata
                                            ?.fr_date
                                        ).format("MMM DD, yyyy hh:mm:ss A")}
                                      {/* Aug 8, 18, 07:34 pm */}
                                    </small>
                                    <small class="text-center star_p">
                                      <b>Br</b>
                                      <br />
                                      {seller_update_details?.Userchaindata
                                        ?.br_date &&
                                        moment(
                                          seller_update_details?.Userchaindata
                                            ?.br_date
                                        ).format("MMM DD, yyyy hh:mm:ss A")}
                                    </small>
                                    <small class="text-center star_p">
                                      <b>Ct</b>
                                      <br />
                                      {seller_update_details?.Userchaindata
                                        ?.ct_date &&
                                        moment(
                                          seller_update_details?.Userchaindata
                                            ?.ct_date
                                        ).format("MMM DD, yyyy hh:mm:ss A")}
                                    </small>
                                    <small class="text-center star_p">
                                      <b>Mq</b>
                                      <br />
                                      {seller_update_details?.Userchaindata
                                        ?.mq_date &&
                                        moment(
                                          seller_update_details?.Userchaindata
                                            ?.mq_date
                                        ).format("MMM DD, yyyy hh:mm:ss A")}
                                    </small>
                                    <small class="text-center star_p">
                                      <b>Duke</b> <br />
                                      {seller_update_details?.Userchaindata
                                        ?.duke_date &&
                                        moment(
                                          seller_update_details?.Userchaindata
                                            ?.duke_date
                                        ).format("MMM DD, yyyy hh:mm:ss A")}
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-12 profile_ul mt-3">
                      <div>
                        <button
                          className={
                            tab == "day"
                              ? "btn btn-primary"
                              : "btn btn-secondary"
                          }
                          onClick={() => {
                            setTab("day");
                          }}
                        >
                          Day wise
                        </button>
                        <button
                          className={
                            tab == "Week"
                              ? "btn btn-primary"
                              : "btn btn-secondary"
                          }
                          onClick={() => {
                            setTab("Week");
                          }}
                        >
                          Week wise
                        </button>
                      </div>

                      {tab == "Week" && week_data?.length > 0 && (
                        <Chart
                          chartType="LineChart"
                          width="100%"
                          height="400px"
                          data={week_data}
                          options={{
                            title: "Week business",
                            curveType: "function",
                            legend: { position: "bottom" },
                          }}
                        />
                      )}
                      {tab == "day" && week_data?.length > 0 && (
                        <Chart
                          chartType="LineChart"
                          width="100%"
                          height="400px"
                          data={day_data}
                          options={{
                            title: "Day business",
                            curveType: "function",
                            legend: { position: "bottom" },
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-lg-12">
                  <div id="profile_content">
                    {" "}
                    <div
                      class="information-title"
                      style={{ marginBottom: "0px" }}
                    >
                      LICENSE
                    </div>
                  </div>
                </div>
              </div>

              {(seller_update_details?.userlicence == "1" ||
                seller_update_details?.addlicenseAdmin == "1") && (
                <div className="mt-3">
                  {(seller_update_details?.userlicence == "1" ||
                    seller_update_details?.addlicenseAdmincount >= "1") && (
                    <div className="liceboxnew mt-2 mb-2">
                      <div className="row">
                        <div className="col-1">create At</div>
                        <div className="col-2">FCID</div>
                        <div className="col-3">Allotted FCID</div>
                        <div className="col-3">Name</div>
                        <div className="col-2">Allotted on</div>
                        <div className="col-1">Submit</div>
                      </div>
                    </div>
                  )}
                  {(seller_update_details?.userlicence == "1" ||
                    seller_update_details?.addlicenseAdmincount == "4") && (
                    <div className="liceboxnew mt-2 mb-2">
                      <div className="row">
                        <div className="col-1">
                          {seller_update_details?.Userchaindata?.br_date &&
                            moment(
                              seller_update_details?.Userchaindata?.br_date
                            ).format("DD MMM YYYY")}
                        </div>
                        <div className="col-2">
                          {seller_update_details?.referId}
                        </div>

                        <div className="col-3">
                          {seller_update_details?.Userchaindata?.license1_id ? (
                            seller_update_details?.Userchaindata?.license1_id
                              ?.referId
                          ) : (
                            <div className="fv-row mb-7">
                              <AsyncPaginate
                                value={userselete}
                                className="liceninput"
                                loadOptions={loadOptionsPalyer}
                                onChange={setuserselete}
                                additional={{
                                  page: 1,
                                }}
                              />
                            </div>
                          )}
                        </div>
                        <div className="col-3">
                          {seller_update_details?.Userchaindata?.license1_id
                            ? seller_update_details?.Userchaindata?.license1_id
                                ?.fullName
                            : "N/A"}
                        </div>
                        <div className="col-2">
                          {seller_update_details?.Userchaindata?.license1_Date
                            ? moment(
                                seller_update_details?.Userchaindata
                                  ?.license1_Date
                              ).format("DD MMM YYYY")
                            : "N/A"}
                        </div>
                        <div className="col-1">
                          {seller_update_details?.Userchaindata?.license1_id ? (
                            ""
                          ) : (
                            <button
                              className="btn btn-primary lcbtn"
                              onClick={() => {
                                hendletoAssLicence(1);
                              }}
                            >
                              submit
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {(seller_update_details?.userlicence == "1" ||
                    seller_update_details?.addlicenseAdmincount >= "3") && (
                    <div className="liceboxnew mt-2 mb-2">
                      <div className="row">
                        <div className="col-1">
                          {seller_update_details?.Userchaindata?.br_date &&
                            moment(
                              seller_update_details?.Userchaindata?.br_date
                            ).format("DD MMM YYYY")}
                        </div>
                        <div className="col-2">
                          {seller_update_details?.referId}
                        </div>

                        <div className="col-3">
                          {seller_update_details?.Userchaindata?.license2_id ? (
                            seller_update_details?.Userchaindata?.license2_id
                              ?.referId
                          ) : (
                            <div className="fv-row mb-7">
                              <AsyncPaginate
                                value={userselete2}
                                className="liceninput"
                                loadOptions={loadOptionsPalyer}
                                onChange={setuserselete2}
                                additional={{
                                  page: 1,
                                }}
                              />
                            </div>
                          )}
                        </div>
                        <div className="col-3">
                          {seller_update_details?.Userchaindata?.license2_id
                            ? seller_update_details?.Userchaindata?.license2_id
                                ?.fullName
                            : "N/A"}
                        </div>
                        <div className="col-2">
                          {seller_update_details?.Userchaindata?.license2_Date
                            ? moment(
                                seller_update_details?.Userchaindata
                                  ?.license2_Date
                              ).format("DD MMM YYYY")
                            : "N/A"}
                        </div>
                        <div className="col-1">
                          {seller_update_details?.Userchaindata?.license2_id ? (
                            ""
                          ) : (
                            <button
                              className="btn btn-primary lcbtn"
                              onClick={() => {
                                hendletoAssLicence2(2);
                              }}
                            >
                              submit
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {(seller_update_details?.userlicence == "1" ||
                    seller_update_details?.addlicenseAdmincount >= "2") && (
                    <div className="liceboxnew mt-2 mb-2">
                      <div className="row">
                        <div className="col-1">
                          {seller_update_details?.Userchaindata?.br_date &&
                            moment(
                              seller_update_details?.Userchaindata?.br_date
                            ).format("DD MMM YYYY")}
                        </div>
                        <div className="col-2">
                          {seller_update_details?.referId}
                        </div>

                        <div className="col-3">
                          {seller_update_details?.Userchaindata?.license3_id ? (
                            seller_update_details?.Userchaindata?.license3_id
                              ?.referId
                          ) : (
                            <div className="fv-row mb-7">
                              <AsyncPaginate
                                value={userselete3}
                                className="liceninput"
                                loadOptions={loadOptionsPalyer}
                                onChange={setuserselete3}
                                additional={{
                                  page: 1,
                                }}
                              />
                            </div>
                          )}
                        </div>
                        <div className="col-3">
                          {seller_update_details?.Userchaindata?.license3_id
                            ? seller_update_details?.Userchaindata?.license3_id
                                ?.fullName
                            : "N/A"}
                        </div>
                        <div className="col-2">
                          {seller_update_details?.Userchaindata?.license3_Date
                            ? moment(
                                seller_update_details?.Userchaindata
                                  ?.license3_Date
                              ).format("DD MMM YYYY")
                            : "N/A"}
                        </div>
                        <div className="col-1">
                          {seller_update_details?.Userchaindata?.license3_id ? (
                            ""
                          ) : (
                            <button
                              className="btn btn-primary lcbtn"
                              onClick={() => {
                                hendletoAssLicence3(3);
                              }}
                            >
                              submit
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {(seller_update_details?.userlicence == "1" ||
                    seller_update_details?.addlicenseAdmincount >= "1") && (
                    <div className="liceboxnew mt-2 mb-2">
                      <div className="row">
                        <div className="col-1">
                          {seller_update_details?.Userchaindata?.br_date &&
                            moment(
                              seller_update_details?.Userchaindata?.br_date
                            ).format("DD MMM YYYY")}
                        </div>
                        <div className="col-2">
                          {seller_update_details?.referId}
                        </div>

                        <div className="col-3">
                          {seller_update_details?.Userchaindata?.license4_id ? (
                            seller_update_details?.Userchaindata?.license4_id
                              ?.referId
                          ) : (
                            <div className="fv-row mb-7">
                              <AsyncPaginate
                                value={userselete4}
                                className="liceninput"
                                loadOptions={loadOptionsPalyer}
                                onChange={setuserselete4}
                                additional={{
                                  page: 1,
                                }}
                              />
                            </div>
                          )}
                        </div>
                        <div className="col-3">
                          {seller_update_details?.Userchaindata?.license4_id
                            ? seller_update_details?.Userchaindata?.license4_id
                                ?.fullName
                            : "N/A"}
                        </div>
                        <div className="col-2">
                          {seller_update_details?.Userchaindata?.license4_Date
                            ? moment(
                                seller_update_details?.Userchaindata
                                  ?.license4_Date
                              ).format("DD MMM YYYY")
                            : "N/A"}
                        </div>
                        <div className="col-1">
                          {seller_update_details?.Userchaindata?.license4_id ? (
                            ""
                          ) : (
                            <button
                              className="btn btn-primary lcbtn"
                              onClick={() => {
                                hendletoAssLicence4(4);
                              }}
                            >
                              submit
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </section>
          </main>
        </div>
      )}
    </div>
  );
}

export default DashBoardApp;
