import React from "react";

// import FooterMain from "../Layout/FooterMain";
// import HeaderMain from "../Layout/HeaderMain";

import AppMainCate from "./MainCategory/AppMainCate";
import WebsiteMainCate from "./MainCategory/WebsiteMainCate";
import NewLouch from "./NewLouch";
import NewLounchApp from "./NewLounchApp";
import SubSubCategory from "./SubSubCategory";
import SubSubCategoryApp from "./SubSubCategoryApp";
import SubCategory from "./SubCategory";
import SubCategoryApp from "./SubCategoryApp";

function SubCategory2({ cat, sliderdate }) {
  function isWebView() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Example for Android WebView (check for "wv")
    if (/wv/.test(userAgent) || /Android.*WebView/.test(userAgent)) {
      return true;
    }

    // Example for iOS WebView (check for "iPhone OS" without "Safari")
    if (/iPhone|iPod|iPad/.test(userAgent) && !/Safari/.test(userAgent)) {
      return true;
    }

    return false; // Assume it's a browser if no WebView signature is detected
  }

  if (isWebView()) {
    console.log("Running inside a WebView");
  } else {
    console.log("Running inside a browser");
  }
  return (
    <div>
      {isWebView() ? (
        <div>
          {/* <HeaderMain /> */}

          <SubCategoryApp />

          {/* <FooterMain /> */}
        </div>
      ) : (
        <div>
          {/* <HeaderMain /> */}
          <SubCategory />
          {/* <FooterMain /> */}
        </div>
      )}
    </div>
  );
}

export default SubCategory2;
